import React, { useState, useEffect } from 'react';
import {
    AppBar,
    Toolbar,
    Button,
    CssBaseline,
    Divider,
    Box,
    CircularProgress,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { baseurl, check_onboarded_status } from '../../util/endpoints';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
    const { loginWithRedirect, isAuthenticated, getAccessTokenSilently, isLoading, logout, user } = useAuth0();
    const [loading, setLoading] = useState(true);
    const [onboardedReason, setOnboardedReason] = useState('');
    const [isOnboarded, setIsOnboarded] = useState(null);
    const [timeoutReached, setTimeoutReached] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
        });
    }, []);

    useEffect(() => {
        let timeoutId;

        const checkOnboarded = async () => {
            try {
                const token = await getAccessTokenSilently();
                const url = baseurl + check_onboarded_status;
                const response = await axios.post(
                    url,
                    { userid: user.sub },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setIsOnboarded(response.data.is_onboarded);
                setOnboardedReason(response.data.reason);
            } catch (error) {
                alert('Error checking onboarded status: ' + error.message);
            } finally {
                setLoading(false);
                clearTimeout(timeoutId);
            }
        };

        if (isAuthenticated && user) {
            // Set a timeout of 5 seconds
            timeoutId = setTimeout(() => {
                setTimeoutReached(true);
                setLoading(false);
            }, 5000);
            checkOnboarded();
        } else {
            setLoading(false);
        }

        // Cleanup the timeout on unmount or dependency change
        return () => clearTimeout(timeoutId);
    }, [isAuthenticated, user, getAccessTokenSilently]);

    return (
        <>
            <CssBaseline />
            <Box />

            <AppBar
                position="sticky"
                elevation={0}
                sx={{
                    backgroundColor: 'background.default',
                    '.MuiToolbar-root': {
                        display: 'flex',
                        justifyContent: 'space-between',
                    },
                    zIndex: theme.zIndex.drawer + 1,
                }}
                data-aos="fade-down"
            >
                <Toolbar>
                    <Typography
                        variant="h6"
                        sx={{
                            cursor: 'pointer',
                            color: 'primary.main',
                            fontWeight: 'bold',
                            flexGrow: 1,
                        }}
                        onClick={() => navigate('/')}
                    >
                        CAREERFORNIA
                    </Typography>
                    {isAuthenticated ? (
                        <>
                            <Typography
                                variant="button"
                                sx={{
                                    margin: 1,
                                    cursor: 'pointer',
                                    color: 'text.primary',
                                    '&:hover': {
                                        color: 'primary.main',
                                    },
                                }}
                                onClick={() => navigate('/dashboard-layout')}
                            >
                                Dashboard
                            </Typography>

                            {isOnboarded && (
                                <>
                                    <Typography
                                        variant="button"
                                        sx={{
                                            margin: 1,
                                            cursor: 'pointer',
                                            color: 'text.primary',
                                            '&:hover': {
                                                color: 'primary.main',
                                            },
                                        }}
                                        onClick={() => navigate('/resume-page')}
                                    >
                                        Edit Resume
                                    </Typography>

                                    <Typography
                                        variant="button"
                                        sx={{
                                            margin: 1,
                                            cursor: 'pointer',
                                            color: 'text.primary',
                                            paddingRight: 2,
                                            '&:hover': {
                                                color: 'primary.main',
                                            },
                                        }}
                                        onClick={() => navigate('/profile')}
                                    >
                                        Profile
                                    </Typography>
                                </>
                            )}

                            <ScrollLink to="hero" smooth={true} duration={500}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: 'primary.main',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: 'primary.dark',
                                        },
                                    }}
                                    onClick={() => logout({ returnTo: window.location.origin })}
                                >
                                    Logout
                                </Button>
                            </ScrollLink>
                        </>
                    ) : (
                        <>

                            <ScrollLink to="hero" smooth={true} duration={500}>
                                <Button
                                    variant="contained"
                                    sx={buttonStyle}
                                    onClick={() => loginWithRedirect()}
                                >
                                    {isLoading ? <CircularProgress size={20} color="inherit" /> : 'Login'}
                                </Button>
                            </ScrollLink>
                        </>
                    )}
                </Toolbar>
                <Divider />
            </AppBar>

            {/* Fallback Message */}
            {timeoutReached && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100vh"
                    bgcolor="background.paper"
                >
                    <Typography variant="body1" color="text.secondary">
                        If it keeps loading, please <Button onClick={() => window.location.reload()}>refresh the page</Button>.
                    </Typography>
                </Box>
            )}
        </>
    );
};

const linkStyle = {
    margin: '0 8px',
    cursor: 'pointer',
    color: 'text.primary',
    '&:hover': {
        color: 'primary.main',
    },
};

const buttonStyle = {
    backgroundColor: 'primary.main',
    color: 'white',
    '&:hover': {
        backgroundColor: 'primary.dark',
    },
    marginLeft: 'auto',
};

export default Navbar;
