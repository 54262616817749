import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import {
  Box,
  Typography,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Tab,
  Tabs,
  CircularProgress,
  Snackbar,
  Alert,
  useMediaQuery,
  useTheme
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  Search as SearchIcon,
  Close as CloseIcon,
  Refresh as RefreshIcon,
  Description as DescriptionIcon,
} from '@mui/icons-material';
import { baseurl, admin_get_users, admin_delete_user } from '../util/endpoints';
import UserResumes from '../components/admin/UserResumes';
import ATSScoreChecker from '../components/admin/ATSScoreChecker';
import ResumeComparer from '../components/admin/ResumeComparer';
import InterviewPrep from '../components/admin/InterviewPrep';

const AdminPage = () => {
  const { getAccessTokenSilently, isAuthenticated, isLoading, user } = useAuth0();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [userDetailOpen, setUserDetailOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Fetch all users
  const fetchUsers = async () => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const userid = localStorage.getItem('userid');
      const response = await axios.post(`${baseurl}${admin_get_users}`, 
        { userid: userid }, // Send the auth0 ID from localStorage
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      setSnackbar({
        open: true,
        message: 'Failed to load users. Please try again.',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // Show user details using the existing data
  const showUserDetails = (userId) => {
    const user = users.find(u => u.userid === userId);
    if (user) {
      setSelectedUser(user);
      setUserDetailOpen(true);
    } else {
      setSnackbar({
        open: true,
        message: 'User not found',
        severity: 'error'
      });
    }
  };

  // Delete a user
  const deleteUser = async () => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      await axios.delete(`${baseurl}${admin_delete_user}${userIdToDelete}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      // Close the confirmation dialog and refresh the user list
      setDeleteConfirmOpen(false);
      fetchUsers();
      
      setSnackbar({
        open: true,
        message: 'User deleted successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error deleting user:', error);
      setSnackbar({
        open: true,
        message: 'Failed to delete user. Please try again.',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      fetchUsers();
    }
  }, [isAuthenticated, isLoading]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCloseUserDetail = () => {
    setUserDetailOpen(false);
    setSelectedUser(null);
  };

  const handleDeleteConfirm = (userId) => {
    setUserIdToDelete(userId);
    setDeleteConfirmOpen(true);
  };

  const handleCloseDeleteConfirm = () => {
    setDeleteConfirmOpen(false);
    setUserIdToDelete(null);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // Filter users based on search term
  const filteredUsers = users.filter((user) => {
    const searchableFields = [
      user.email,
      user.username,
      user.first_name,
      user.last_name,
      user.current_location,
      user.role_preference,
      user.job_type
    ].filter(Boolean).join(' ').toLowerCase();
    
    return searchableFields.includes(searchTerm.toLowerCase());
  });

  // Sort users by createdAt date in descending order and limit to 20
  const sortedAndLimitedUsers = [...filteredUsers]
    .sort((a, b) => {
      // If there's a createdAt field, use it for sorting
      if (a.createdAt && b.createdAt) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      }
      // As a fallback, use user ID
      return b.id - a.id;
    })
    .slice(0, 20);

  // Format date for display
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth={false} sx={{ mt: 4, mb: 4, px: isMobile ? 1 : 4 }}>
      <Paper 
        sx={{ 
          p: 3, 
          borderRadius: '10px',
          boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 2px 0px',
          border: '1px solid rgba(0, 0, 0, 0.1)',
          mb: 3
        }}
      >
        <Box display="flex" flexDirection={isMobile ? "column" : "row"} justifyContent="space-between" alignItems={isMobile ? "flex-start" : "center"} mb={3}>
          <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: '600', mb: isMobile ? 2 : 0 }}>
            Admin Dashboard
          </Typography>
          
          <Box display="flex" mt={isMobile ? 2 : 0}>
            <TextField
              variant="outlined"
              placeholder="Search users..."
              size="small"
              value={searchTerm}
              onChange={handleSearch}
              sx={{ 
                minWidth: isMobile ? '100%' : 240,
                backgroundColor: '#f8f9fa',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px'
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
            <IconButton 
              onClick={fetchUsers} 
              sx={{ ml: 1 }}
              title="Refresh users list"
            >
              <RefreshIcon />
            </IconButton>
          </Box>
        </Box>

        <Tabs 
          value={tabValue} 
          onChange={handleTabChange}
          sx={{
            mb: 3,
            '& .MuiTabs-indicator': {
              display: 'none'  // Remove the bottom indicator line
            },
            '& .MuiTab-root': {
              color: '#666',
              '&.Mui-selected': {
                color: '#000',
                backgroundColor: 'transparent'  // Remove blue highlight
              },
              '&:hover': {
                color: '#000',
                backgroundColor: 'transparent'  // Remove hover highlight
              }
            }
          }}
        >
          <Tab label="Resumes" />
          <Tab label="ATS Score Checker" />
          <Tab label="Compare Resumes" />
          <Tab label="Interview Prep" />
          <Tab label="Users" />
        </Tabs>

        {/* Resumes Tab */}
        {tabValue === 0 && (
          <Box>
            <UserResumes />
          </Box>
        )}

        {/* ATS Score Checker Tab */}
        {tabValue === 1 && (
          <Box>
            <ATSScoreChecker />
          </Box>
        )}
        
        {/* Resume Comparer Tab */}
        {tabValue === 2 && (
          <Box>
            <ResumeComparer />
          </Box>
        )}

        {/* Interview Prep Tab */}
        {tabValue === 3 && (
          <Box>
            <InterviewPrep />
          </Box>
        )}

        {/* Users Tab */}
        <Box role="tabpanel" hidden={tabValue !== 4}>
          {tabValue === 4 && (
            // Users tab content
            <Box>
              {/* Users table content goes here */}
              {loading ? (
                <Box display="flex" justifyContent="center" py={8}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <TableContainer component={Paper} variant="outlined" sx={{ boxShadow: 'none' }}>
                    <Table size="medium">
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>Username/Email</TableCell>
                          <TableCell>Created</TableCell>
                          <TableCell align="right">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sortedAndLimitedUsers.map((user) => (
                          <TableRow key={user.userid} hover>
                            <TableCell>{user.userid}</TableCell>
                            <TableCell>
                              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                {user.username || 'No Username'}
                              </Typography>
                              <Typography variant="caption" color="text.secondary">
                                {user.email}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {formatDate(user.createdAt)}
                            </TableCell>
                            <TableCell align="right">
                              <IconButton
                                size="small"
                                color="primary"
                                onClick={() => showUserDetails(user.userid)}
                              >
                                <VisibilityIcon fontSize="small" />
                              </IconButton>
                              <IconButton 
                                size="small" 
                                color="error"
                                onClick={() => handleDeleteConfirm(user.userid)}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                    Showing the 20 most recent users
                  </Typography>
                </>
              )}
            </Box>
          )}
        </Box>
      </Paper>

      {/* User detail dialog */}
      <Dialog 
        open={userDetailOpen} 
        onClose={handleCloseUserDetail}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Typography variant="h6" component="span" sx={{ fontWeight: 600 }}>
            User Details
          </Typography>
          <IconButton 
            edge="end" 
            color="inherit" 
            onClick={handleCloseUserDetail} 
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ py: 3 }}>
          {selectedUser && (
            <Box>
              <Box sx={{ display: 'grid', gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' }, gap: 2, mb: 3 }}>
                <Box>
                  <Typography variant="caption" color="text.secondary">
                    User ID
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUser.userid}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption" color="text.secondary">
                    Registration Date
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {formatDate(selectedUser.createdAt)}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption" color="text.secondary">
                    Name
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {`${selectedUser.first_name || ''} ${selectedUser.last_name || ''}`.trim() || 'N/A'}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption" color="text.secondary">
                    Username
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUser.username || 'N/A'}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption" color="text.secondary">
                    Email
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUser.email || 'N/A'}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption" color="text.secondary">
                    Phone
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUser.phone ? `${selectedUser.country_code || ''} ${selectedUser.phone}`.trim() : 'N/A'}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption" color="text.secondary">
                    Location
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUser.location_preference || selectedUser.current_location || 'N/A'}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption" color="text.secondary">
                    LinkedIn
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUser.linkedin ? (
                      <a 
                        href={selectedUser.linkedin.startsWith('http') ? selectedUser.linkedin : `https://${selectedUser.linkedin}`} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{ color: theme.palette.primary.main }}
                      >
                        {selectedUser.linkedin}
                      </a>
                    ) : 'N/A'}
                  </Typography>
                </Box>
                {selectedUser.github_link && (
                  <Box>
                    <Typography variant="caption" color="text.secondary">
                      GitHub
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <a 
                        href={selectedUser.github_link.startsWith('http') ? selectedUser.github_link : `https://${selectedUser.github_link}`} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{ color: theme.palette.primary.main }}
                      >
                        {selectedUser.github_link}
                      </a>
                    </Typography>
                  </Box>
                )}
                {selectedUser.portfolio_link && (
                  <Box>
                    <Typography variant="caption" color="text.secondary">
                      Portfolio
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <a 
                        href={selectedUser.portfolio_link.startsWith('http') ? selectedUser.portfolio_link : `https://${selectedUser.portfolio_link}`} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{ color: theme.palette.primary.main }}
                      >
                        {selectedUser.portfolio_link}
                      </a>
                    </Typography>
                  </Box>
                )}
              </Box>
              
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                <Button onClick={handleCloseUserDetail} color="primary">
                  Close
                </Button>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleCloseDeleteConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete User?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone. Are you sure you want to delete this user and all associated data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirm} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteUser} color="error" variant="contained" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AdminPage; 