import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/job_preferences.css';
import { baseurl, save_user_preferences_v2, user_details } from '../util/endpoints.js';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useAuth0 } from '@auth0/auth0-react';
import WelcomeAlert from '../components/welcome_alert.js';
import countryCodes from '../util/countryCodes';
import { Navigate } from 'react-router-dom';
import { Box, CircularProgress, useTheme } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const stepsTitles = ['Upload Resume', 'Add Job Preferences', 'Start Applying'];

const JobPreferences = () => {
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const navigate = useNavigate();
    const userid = localStorage.getItem('userid');
    const [preferences, setPreferences] = useState({
        userid: userid,
        linkedin: '',
        first_name: '',
        last_name: '',
        gender: '',
        phone: '',
        email: '',
        drug_test: '',
        sponsorship: '',
        onsite: '',
        location_preference: '',
        portfolio_link: '',
        github_link: '',
        country_code: ''  // Added country_code
    });
    const [isLoading, setIsLoading] = useState(true);
    const [isRelocate, setIsRelocate] = useState(false);
    const [showWelcome, setShowWelcome] = useState(false);
    const theme = useTheme();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const token = await getAccessTokenSilently();
                const url = baseurl + user_details;
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ userid: userid }),
                });
                if (response.ok) {
                    const data = await response.json();
                    setPreferences((prevPreferences) => ({
                        ...prevPreferences,
                        linkedin: data.linkedin,
                        first_name: data.first_name,
                        last_name: data.last_name,
                        onsite: data.onsite,
                        sponsorship: data.sponsorship,
                        drug_test: data.drug_test,
                        phone: data.phone,
                        email: data.email,
                        location_preference: data.location_preference || '',
                        gender: data.gender,
                        portfolio_link: data.portfolio_link || '',
                        github_link: data.github_link || '',
                        country_code: data.country_code || ''  // Added country_code
                    }));
                    if (data.location_preference && data.location_preference.includes('(open to relocate)')) {
                        setIsRelocate(true);
                    }
                } else {
                    throw new Error('Failed to fetch user details');
                }
            } catch (error) {
                alert('Error fetching user details: ' + error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [userid, getAccessTokenSilently]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPreferences((prev) => ({ ...prev, [name]: value }));
        if (name === 'location_preference' && value === '') {
            setIsRelocate(false);
        }
    };

    const handleMobileChange = (e) => {
        const { name, value } = e.target;
        if (value === '' || (value.length <= 10 && /^\d+$/.test(value))) {
            setPreferences({ ...preferences, [name]: value });
        }
    };

    const handleRelocateChange = (e) => {
        const checked = e.target.checked;
        setIsRelocate(checked);
        setPreferences((prev) => {
            const newLocation = checked ? `${prev.location_preference} (open to relocate)` : prev.location_preference.replace(' (open to relocate)', '');
            return { ...prev, location_preference: newLocation };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Check required fields
        const requiredFields = {
            'linkedin': 'LinkedIn URL',
            'first_name': 'First Name',
            'last_name': 'Last Name',
            'gender': 'Gender',
            'phone': 'Mobile Number',
            'email': 'Email',
            'drug_test': 'Drug Test preference',
            'sponsorship': 'Sponsorship status',
            'onsite': 'Work mode preference',
            'country_code': 'Country Code',
            'location_preference': 'Location'
        };
        
        const missingFields = Object.entries(requiredFields)
            .filter(([field]) => !preferences[field])
            .map(([_, label]) => label);
        
        if (missingFields.length > 0) {
            toast.error(`Please fill in the following required fields: ${missingFields.join(', ')}`, {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: {
                    backgroundColor: '#ffebee',
                    color: '#d32f2f',
                    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                    fontSize: '14px',
                    padding: '16px',
                    whiteSpace: 'pre-line'
                }
            });
            return;
        }

        try {
            const token = await getAccessTokenSilently();
            const url = baseurl + save_user_preferences_v2;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(preferences),
            });
            if (response.ok) {
                setShowWelcome(true);
                setTimeout(() => {
                    navigate('../dashboard-layout');
                }, 30000);
            } else {
                throw new Error('Submission failed');
            }
        } catch (error) {
            alert('Error submitting form: ' + error.message);
        }
    };

    if (showWelcome) {
        return <WelcomeAlert />;
    }
    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress
                    size={60}
                    thickness={4}
                    sx={{
                        color: theme.palette.primary.main,
                    }}
                />
            </Box>
        );
    }

    if (!isAuthenticated) {
        return <Navigate to="/" />;
    }

    return (
        <div className="job-preferences">
            <div className="progress-bar">
                {stepsTitles.map((title, index) => (
                    <div key={title} className="step-container">
                        <div className={`step ${index === 1 ? 'active-step' : ''}`}>
                            {index + 1}
                        </div>
                        <div className="step-title">{title}</div>
                    </div>
                ))}
            </div>
            <div style={{ textAlign: 'center' }}>
                <h5 style={{ fontWeight: 'bold' }}>Few details to get you started</h5>
                <p>This information will be used in your resume and for job applications, you can change it later as well</p>
            </div>
            <form onSubmit={handleSubmit}>
                <label>LinkedIn URL</label>
                <input
                    type="text"
                    name="linkedin"
                    placeholder="LinkedIn URL (cannot be changed later)"
                    value={preferences.linkedin === null ? '' : preferences.linkedin}
                    onChange={handleChange}
                    className="job-preferences-input"
                    required
                    disabled={isLoading}
                />
                {isLoading ? <Skeleton height={20} /> : null}
                <label>Email address</label>
                <input
                    type="text"
                    name="email"
                    placeholder="Enter your email address for job applications"
                    value={preferences.email}
                    onChange={handleChange}
                    className="job-preferences-input"
                    required
                    disabled={isLoading}
                />
                {isLoading ? <Skeleton height={20} /> : null}
                <label>First Name</label>
                <input
                    type="text"
                    name="first_name"
                    placeholder="Enter your real first name for job applications"
                    value={preferences.first_name}
                    onChange={handleChange}
                    className="job-preferences-input"
                    required
                    disabled={isLoading}
                />
                {isLoading ? <Skeleton height={20} /> : null}
                <label>Last Name</label>
                <input
                    type="text"
                    name="last_name"
                    placeholder="Enter your real last name for job applications"
                    value={preferences.last_name}
                    onChange={handleChange}
                    className="job-preferences-input"
                    required
                    disabled={isLoading}
                />
                {isLoading ? <Skeleton height={20} /> : null}
                <label>Country Code</label>
                <select
                    name="country_code"
                    value={preferences.country_code}
                    onChange={handleChange}
                    className="job-preferences-select"
                    required
                    disabled={isLoading}
                >
                    <option value="">Select Country Code</option>
                    {countryCodes.map((code) => (
                        <option key={code.value} value={code.value}>
                            {code.label}
                        </option>
                    ))}
                </select>
                {isLoading ? <Skeleton height={20} /> : null}
                <label>Mobile Number</label>
                <input
                    type="text"
                    name="phone"
                    value={preferences.phone}
                    onChange={handleMobileChange}
                    className="job-preferences-input"
                    placeholder="Will be used in job applications & Resumes"
                    required
                    disabled={isLoading}
                />
                {isLoading ? <Skeleton height={20} /> : null}
                <label>Portfolio/website</label>
                <input
                    type="text"
                    name="portfolio_link"
                    placeholder="Enter your portfolio or website URL"
                    value={preferences.portfolio_link}
                    onChange={handleChange}
                    className="job-preferences-input"
                    disabled={isLoading}
                />
                {isLoading ? <Skeleton height={20} /> : null}
                <label>Github</label>
                <input
                    type="text"
                    name="github_link"
                    placeholder="Enter your Github URL"
                    value={preferences.github_link}
                    onChange={handleChange}
                    className="job-preferences-input"
                    disabled={isLoading}
                />
                {isLoading ? <Skeleton height={20} /> : null}
                <label>Gender</label>
                <select name="gender" value={preferences.gender} onChange={handleChange} className="job-preferences-select" required disabled={isLoading}>
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="non-binary">Non-binary</option>
                    <option value="prefer_not_to_say">Prefer not to say</option>
                </select>
                {isLoading ? <Skeleton height={20} /> : null}
                <label>Are you willing to take a drug test in accordance with local law/regulations if required?</label>
                <select name="drug_test" value={preferences.drug_test} onChange={handleChange} className="job-preferences-select" required disabled={isLoading}>
                    <option value="">Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </select>
                {isLoading ? <Skeleton height={20} /> : null}
                <label>Will you now or in the future require sponsorship to work in the U.S?</label>
                <select name="sponsorship" value={preferences.sponsorship} onChange={handleChange} className="job-preferences-select" required disabled={isLoading}>
                    <option value="">Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </select>
                {isLoading ? <Skeleton height={20} /> : null}
                <label>Preferred work mode</label>
                <select name="onsite" value={preferences.onsite} onChange={handleChange} className="job-preferences-select" required disabled={isLoading}>
                    <option value="">Select</option>
                    <option value="on_site">On site</option>
                    <option value="hybrid">Hybrid</option>
                    <option value="remote">Remote</option>
                    <option value="Any">No preference</option>
                </select>
                {isLoading ? <Skeleton height={20} /> : null}
                <label>Location</label>
                <input
                    type="text"
                    name="location_preference"
                    value={preferences.location_preference}
                    onChange={handleChange}
                    className="job-preferences-input"
                    placeholder="Enter your preferred location"
                    disabled={isLoading}
                    required
                />
                {isLoading ? <Skeleton height={20} /> : null}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ marginRight: '10px' }}>Open to relocate</label>
                    <input
                        type="checkbox"
                        checked={isRelocate}
                        onChange={handleRelocateChange}
                        disabled={!preferences.location_preference || isLoading}
                    />
                </div>
                <button type="submit" disabled={isLoading}>Save Preferences</button>
            </form>
            <ToastContainer />
        </div>
    );
};

export default JobPreferences;