import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Paper,
  Button,
  Grid,
  CircularProgress,
  Divider,
  useTheme,
  useMediaQuery,
  Tooltip,
  IconButton,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { 
  DeleteOutline as ClearIcon,
  ContentCopy as CopyIcon,
  ContentPaste as PasteIcon,
  Edit as EditIcon,
  CompareArrows as CompareIcon
} from '@mui/icons-material';
import { motion } from 'framer-motion';

// Component to highlight differences between two texts
const HighlightedText = ({ text, differences, isLeft }) => {
  if (!text) return null;
  
  // If no differences detected, return the plain text
  if (!differences || !differences.length) {
    return <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{text}</pre>;
  }

  // Sort differences by starting position to process them in order
  const sortedDiffs = [...differences].sort((a, b) => a.startPos - b.startPos);
  
  let lastPos = 0;
  const segments = [];

  sortedDiffs.forEach((diff, index) => {
    // Add text before the difference
    if (diff.startPos > lastPos) {
      segments.push({
        text: text.substring(lastPos, diff.startPos),
        highlighted: false
      });
    }

    // Add the difference
    const diffText = text.substring(diff.startPos, diff.endPos);
    segments.push({
      text: diffText,
      highlighted: true,
      type: diff.type // added or removed
    });

    lastPos = diff.endPos;
  });

  // Add remaining text after the last difference
  if (lastPos < text.length) {
    segments.push({
      text: text.substring(lastPos),
      highlighted: false
    });
  }

  return (
    <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', margin: 0, height: '100%', overflowY: 'auto' }}>
      {segments.map((segment, index) => {
        if (!segment.highlighted) {
          return <span key={index}>{segment.text}</span>;
        }
        
        let bgColor = '';
        if (isLeft && segment.type === 'removed') {
          bgColor = 'rgba(255, 0, 0, 0.2)'; // Red for removed content on left
        } else if (!isLeft && segment.type === 'added') {
          bgColor = 'rgba(0, 255, 0, 0.2)'; // Green for added content on right
        }
        
        return (
          <span 
            key={index} 
            style={{ 
              backgroundColor: bgColor,
              textDecoration: !bgColor ? 'none' : 'none'
            }}
          >
            {segment.text}
          </span>
        );
      })}
    </pre>
  );
};

const ResumeComparer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  const [leftText, setLeftText] = useState('');
  const [rightText, setRightText] = useState('');
  const [differences, setDifferences] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [mode, setMode] = useState('edit'); // 'edit' or 'compare'

  // Function to find differences between two strings
  const findDifferences = (text1, text2) => {
    if (!text1 || !text2) {
      setDifferences(null);
      return;
    }
    
    setLoading(true);
    
    try {
      // Convert each text into an array of lines
      const lines1 = text1.split('\n');
      const lines2 = text2.split('\n');
      
      const leftDiffs = [];
      const rightDiffs = [];
      
      // Simple line-by-line comparison
      // Ideally this would use a more sophisticated diff algorithm like Myers diff
      let maxLines = Math.max(lines1.length, lines2.length);
      
      for (let i = 0; i < maxLines; i++) {
        const line1 = lines1[i] || '';
        const line2 = lines2[i] || '';
        
        if (line1 !== line2) {
          // Calculate position in the original text
          const leftStartPos = lines1.slice(0, i).join('\n').length + (i > 0 ? 1 : 0);
          const leftEndPos = leftStartPos + line1.length;
          
          const rightStartPos = lines2.slice(0, i).join('\n').length + (i > 0 ? 1 : 0);
          const rightEndPos = rightStartPos + line2.length;
          
          // Add difference
          leftDiffs.push({
            startPos: leftStartPos,
            endPos: leftEndPos,
            type: 'removed'
          });
          
          rightDiffs.push({
            startPos: rightStartPos,
            endPos: rightEndPos,
            type: 'added'
          });
        }
      }
      
      setDifferences({
        left: leftDiffs,
        right: rightDiffs
      });
    } catch (error) {
      console.error('Error comparing texts:', error);
      setError('Error comparing texts. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Clear both text fields
  const handleClearAll = () => {
    setLeftText('');
    setRightText('');
    setDifferences(null);
    setError('');
  };

  // Handle copying text to clipboard
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        console.log('Text copied to clipboard');
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };

  // Handle pasting from clipboard
  const handlePaste = async (side) => {
    try {
      const text = await navigator.clipboard.readText();
      if (side === 'left') {
        setLeftText(text);
      } else {
        setRightText(text);
      }
    } catch (err) {
      console.error('Failed to paste: ', err);
      setError('Failed to paste from clipboard. Please try copying and pasting manually.');
    }
  };

  // Handle mode change
  const handleModeChange = (event, newMode) => {
    if (newMode !== null) {
      setMode(newMode);
      if (newMode === 'compare' && leftText && rightText) {
        findDifferences(leftText, rightText);
      }
    }
  };

  // Run comparison when both texts change and in compare mode
  useEffect(() => {
    if (mode === 'compare' && leftText && rightText) {
      findDifferences(leftText, rightText);
    }
  }, [leftText, rightText, mode]);

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Paper
        elevation={0}
        sx={{
          p: 3,
          borderRadius: '10px',
          border: '1px solid rgba(0, 0, 0, 0.1)',
          mb: 3
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h5" fontWeight={600}>
            Resume Comparison Tool
          </Typography>
          <ToggleButtonGroup
            value={mode}
            exclusive
            onChange={handleModeChange}
            aria-label="comparison mode"
            size="small"
          >
            <ToggleButton value="edit" aria-label="edit mode">
              <EditIcon fontSize="small" sx={{ mr: 1 }} /> Edit
            </ToggleButton>
            <ToggleButton value="compare" aria-label="compare mode">
              <CompareIcon fontSize="small" sx={{ mr: 1 }} /> Compare
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        
        <Typography variant="body2" color="text.secondary" paragraph>
          {mode === 'edit' 
            ? 'Paste two resumes to compare them. Switch to Compare mode to see differences highlighted.' 
            : 'Differences highlighted: red shows removed content, green shows added content. Switch to Edit mode to make changes.'}
        </Typography>

        {error && (
          <Box sx={{ mb: 2 }}>
            <Typography color="error">{error}</Typography>
          </Box>
        )}

        <Grid container spacing={3}>
          {/* Left Resume */}
          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                <Typography variant="subtitle1" fontWeight={500}>
                  Resume 1
                </Typography>
                <Box>
                  <Tooltip title="Paste from clipboard">
                    <IconButton 
                      size="small"
                      onClick={() => handlePaste('left')}
                      disabled={mode === 'compare'}
                    >
                      <PasteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Copy to clipboard">
                    <IconButton 
                      size="small"
                      onClick={() => handleCopy(leftText)}
                      disabled={!leftText}
                    >
                      <CopyIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Clear">
                    <IconButton 
                      size="small"
                      onClick={() => setLeftText('')}
                      disabled={!leftText || mode === 'compare'}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              
              {mode === 'edit' ? (
                <TextField
                  fullWidth
                  multiline
                  rows={20}
                  variant="outlined"
                  placeholder="Paste first resume text here..."
                  value={leftText}
                  onChange={(e) => setLeftText(e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: '#f8f9fa',
                    }
                  }}
                />
              ) : (
                <Paper
                  variant="outlined"
                  sx={{
                    height: '400px',
                    p: 2,
                    backgroundColor: '#f8f9fa',
                    borderRadius: '4px',
                    display: 'flex',
                    alignItems: 'flex-start',
                    overflowY: 'auto'
                  }}
                >
                  <HighlightedText 
                    text={leftText} 
                    differences={differences?.left}
                    isLeft={true}
                  />
                </Paper>
              )}
            </Box>
          </Grid>
          
          {/* Right Resume */}
          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                <Typography variant="subtitle1" fontWeight={500}>
                  Resume 2
                </Typography>
                <Box>
                  <Tooltip title="Paste from clipboard">
                    <IconButton 
                      size="small"
                      onClick={() => handlePaste('right')}
                      disabled={mode === 'compare'}
                    >
                      <PasteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Copy to clipboard">
                    <IconButton 
                      size="small"
                      onClick={() => handleCopy(rightText)}
                      disabled={!rightText}
                    >
                      <CopyIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Clear">
                    <IconButton 
                      size="small"
                      onClick={() => setRightText('')}
                      disabled={!rightText || mode === 'compare'}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              
              {mode === 'edit' ? (
                <TextField
                  fullWidth
                  multiline
                  rows={20}
                  variant="outlined"
                  placeholder="Paste second resume text here..."
                  value={rightText}
                  onChange={(e) => setRightText(e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: '#f8f9fa',
                    }
                  }}
                />
              ) : (
                <Paper
                  variant="outlined"
                  sx={{
                    height: '400px',
                    p: 2,
                    backgroundColor: '#f8f9fa',
                    borderRadius: '4px',
                    display: 'flex',
                    alignItems: 'flex-start',
                    overflowY: 'auto'
                  }}
                >
                  <HighlightedText 
                    text={rightText} 
                    differences={differences?.right}
                    isLeft={false}
                  />
                </Paper>
              )}
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
          <Button 
            variant="outlined" 
            color="error" 
            onClick={handleClearAll}
            disabled={(!leftText && !rightText) || mode === 'compare'}
            sx={{ mr: 1 }}
          >
            Clear All
          </Button>
          {mode === 'edit' && leftText && rightText && (
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => setMode('compare')}
            >
              Compare Resumes
            </Button>
          )}
          {mode === 'compare' && (
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => setMode('edit')}
            >
              Edit Resumes
            </Button>
          )}
        </Box>

        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
            <CircularProgress size={24} />
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default ResumeComparer; 