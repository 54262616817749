import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  IconButton,
  Box,
  Tabs,
  Tab,
  Paper,
  Divider,
  Alert,
  ThemeProvider,
  createTheme,
  alpha
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

// Create a custom theme for the modal
const modalTheme = createTheme({
  palette: {
    primary: {
      main: '#3a86ff',
      light: '#5f9dff',
      dark: '#1e5fcf',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#495057',
      light: '#636b72',
      dark: '#343a40',
    },
    background: {
      default: '#f8f9fa',
      paper: '#ffffff',
    },
    text: {
      primary: '#212529',
      secondary: '#495057',
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h6: {
      fontWeight: 600,
      fontSize: '1.15rem',
    },
    subtitle1: {
      fontWeight: 600,
    },
    subtitle2: {
      fontWeight: 500,
      color: '#495057',
    },
    body1: {
      fontSize: '0.95rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none',
          padding: '8px 22px',
          fontWeight: 500,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: '0 4px 12px rgba(58, 134, 255, 0.2)',
          },
        },
        contained: {
          '&:hover': {
            backgroundColor: '#5f9dff',
          },
        },
        outlined: {
          borderWidth: '1.5px',
          '&:hover': {
            borderWidth: '1.5px',
            backgroundColor: 'rgba(58, 134, 255, 0.08)',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#3a86ff',
              borderWidth: '1.5px',
            },
            '&:hover fieldset': {
              borderColor: '#5f9dff',
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3a86ff',
            borderWidth: '1.5px',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#5f9dff',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 500,
          fontSize: '0.95rem',
          '&.Mui-selected': {
            color: '#3a86ff',
            fontWeight: 600,
          }
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#3a86ff',
          height: '3px',
          borderRadius: '3px 3px 0 0',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
        standardInfo: {
          backgroundColor: 'rgba(58, 134, 255, 0.1)',
          color: '#1e5fcf',
          '& .MuiAlert-icon': {
            color: '#3a86ff',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        outlined: {
          borderColor: '#dee2e6',
          borderRadius: '10px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.2rem',
          fontWeight: 600,
        },
      },
    },
  },
});

// Custom styled components for better UI
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '90%',
    maxWidth: 1000,
    maxHeight: '90vh',
    borderRadius: '12px',
    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
  },
}));

const StyledTabPanel = styled(Box)(({ theme }) => ({
  padding: '24px 16px',
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <StyledTabPanel
      role="tabpanel"
      hidden={value !== index}
      id={`interview-tabpanel-${index}`}
      aria-labelledby={`interview-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </StyledTabPanel>
  );
};

const InterviewModal = ({ open, onClose, onSave }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [profileType, setProfileType] = useState('dataEngineer');
  const [jobDescription, setJobDescription] = useState('');
  const [resumeContent, setResumeContent] = useState('');
  const [specialInstructions, setSpecialInstructions] = useState('');
  const [candidateName, setCandidateName] = useState('');

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSubmit = () => {
    onSave({
      profileType,
      jobDescription,
      resumeContent,
      specialInstructions,
      candidateName
    });
    onClose();
  };

  return (
    <ThemeProvider theme={modalTheme}>
      <StyledDialog 
        open={open} 
        onClose={onClose}
        aria-labelledby="interview-modal-title"
        fullWidth
      >
        <DialogTitle id="interview-modal-title" sx={{ 
          m: 0, 
          p: 3, 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          borderBottom: '1px solid #f0f0f0'
        }}>
          <Typography variant="h6" component="div">
            Set Up Your Interview Practice
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: 'text.secondary',
              transition: 'all 0.2s',
              '&:hover': {
                color: 'text.primary',
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        
        <DialogContent sx={{ p: 3, pt: 4 }}>
          <Box sx={{ mb: 4 }}>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, mb: 4, mt: 4 }}>
              <TextField
                label="Your Name"
                variant="outlined"
                placeholder="Enter your name"
                fullWidth
                value={candidateName}
                onChange={(e) => setCandidateName(e.target.value)}
                sx={{ flex: { sm: 1 } }}
              />
              
              <FormControl variant="outlined" sx={{ flex: { sm: 1 } }}>
                <InputLabel id="profile-type-label">Interview Role</InputLabel>
                <Select
                  labelId="profile-type-label"
                  id="profile-type"
                  value={profileType}
                  onChange={(e) => setProfileType(e.target.value)}
                  label="Interview Role"
                >
                  <MenuItem value="dataEngineer">Data Engineer</MenuItem>
                  <MenuItem value="sdetQA">SDET/QA Engineer</MenuItem>
                  <MenuItem value="dotNet">.NET Developer</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Alert severity="info" sx={{ 
              mb: 4, 
              borderRadius: '10px', 
              boxShadow: '0 2px 8px rgba(58, 134, 255, 0.12)'
            }}>
              <Typography variant="body2">
                <strong>Note:</strong> The AI is already trained to be proficient with all technical skills for this role. 
                Just add your previous experience, education, and any other relevant information.
              </Typography>
            </Alert>

            <Paper 
              variant="outlined" 
              sx={{ 
                mb: 3, 
                overflow: 'hidden',
                boxShadow: '0 2px 12px rgba(0, 0, 0, 0.04)'
              }}
            >
              <Tabs 
                value={activeTab} 
                onChange={handleTabChange} 
                aria-label="interview setup tabs"
                variant="fullWidth"
                sx={{ borderBottom: 1, borderColor: 'divider' }}
              >
                <Tab 
                  label="Job Description" 
                  id="interview-tab-0" 
                  aria-controls="interview-tabpanel-0" 
                />
                <Tab 
                  label="Your Personal Information" 
                  id="interview-tab-1" 
                  aria-controls="interview-tabpanel-1" 
                />
                <Tab 
                  label="Special Instructions" 
                  id="interview-tab-2" 
                  aria-controls="interview-tabpanel-2" 
                />
              </Tabs>

              <TabPanel value={activeTab} index={0}>
                <Typography variant="subtitle2" gutterBottom>
                  Paste the job description you're interviewing for:
                </Typography>
                <TextField
                  autoFocus={activeTab === 0}
                  multiline
                  rows={10}
                  variant="outlined"
                  placeholder="Paste the job description here. This helps tailor the interview responses to the specific role."
                  fullWidth
                  value={jobDescription}
                  onChange={(e) => setJobDescription(e.target.value)}
                />
              </TabPanel>

              <TabPanel value={activeTab} index={1}>
                <Typography variant="subtitle2" gutterBottom>
                  Mention your previous experience, education, and any other relevant information:
                </Typography>
                <TextField
                  autoFocus={activeTab === 1}
                  multiline
                  rows={10}
                  variant="outlined"
                  placeholder="Include your relevant work experience and education. No need to include technical skills as the AI is already trained on those."
                  fullWidth
                  value={resumeContent}
                  onChange={(e) => setResumeContent(e.target.value)}
                />
              </TabPanel>

              <TabPanel value={activeTab} index={2}>
                <Typography variant="subtitle2" gutterBottom>
                  Add any special instructions for the AI interviewer:
                </Typography>
                <Typography variant="body2" gutterBottom>
                  First try without any instructions most of the cases AI already does better.
                </Typography>
                <TextField
                  autoFocus={activeTab === 2}
                  multiline
                  rows={10}
                  variant="outlined"
                  placeholder="Add special instructions for how the AI should respond (e.g., 'Keep answers technical but beginner-friendly', 'Focus on architecture examples')"
                  fullWidth
                  value={specialInstructions}
                  onChange={(e) => setSpecialInstructions(e.target.value)}
                />
              </TabPanel>
            </Paper>
          </Box>
        </DialogContent>

        <DialogActions sx={{ 
          p: 3, 
          borderTop: '1px solid #f0f0f0',
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <Button 
            onClick={onClose} 
            sx={{ 
              color: 'text.secondary',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              }
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit} 
            variant="contained" 
            color="primary"
            disabled={!candidateName.trim()}
          >
            Start Interview Practice
          </Button>
        </DialogActions>
      </StyledDialog>
    </ThemeProvider>
  );
};

export default InterviewModal; 