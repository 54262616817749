import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import {
  Box,
  Typography,
  Paper,
  Button,
  CircularProgress,
  Divider,
  useTheme,
  Grid,
  Alert,
  IconButton,
  Tooltip,
  Stack,
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { motion } from 'framer-motion';
import { baseurl, resumes_list, fetch_tailored_resume, admin_get_users, generate_tailored_resume } from '../../util/endpoints';
import ResumeDataModal from '../dashboard/editGeneratedResume/resume_edit_dialog_modal.js';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';
import { generatePDF } from '../pdfFormatter';

const UserResumes = () => {
  const theme = useTheme();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [usersLoading, setUsersLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [resumes, setResumes] = useState([]);
  const [resumeCount, setResumeCount] = useState(0);
  const [userProfileData, setUserProfileData] = useState(null);
  const [error, setError] = useState('');
  const [downloadDataByJobId, setDownloadDataByJobId] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  
  // Generate resume form state
  const [isGenerateResumeModalOpen, setIsGenerateResumeModalOpen] = useState(false);
  const [jobTitle, setJobTitle] = useState('');
  const [company, setCompany] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [skills, setSkills] = useState('');
  const [generatingResume, setGeneratingResume] = useState(false);
  const [newResumeData, setNewResumeData] = useState(null);
  const [showDownloadButton, setShowDownloadButton] = useState(false);

  // Fetch users
  const fetchLatestUsers = async () => {
    try {
      setUsersLoading(true);
      const token = await getAccessTokenSilently();
      const userid = localStorage.getItem('userid');
      const response = await axios.post(`${baseurl}${admin_get_users}`, 
        { userid: userid }, // Send the auth0 ID from localStorage
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      
      // Sort users in descending order (newest first) and limit to 20
      const sortedUsers = [...response.data].sort((a, b) => {
        // If there's a createdAt field, use it for sorting
        if (a.createdAt && b.createdAt) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        }
        // As a fallback, use user ID (assuming higher ID means newer user)
        return b.userid - a.userid;
      }).slice(0, 500);
      
      setUsers(sortedUsers);
      setError('');
    } catch (err) {
      console.error('Error fetching users:', err);
      setError('Failed to load users. Please try again.');
    } finally {
      setUsersLoading(false);
    }
  };

  // Fetch user's resumes
  const fetchUserResumes = async (userId) => {
    if (!userId) return;
    
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const response = await axios.post(`${baseurl}${resumes_list}`, 
        { userid: userId },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      
      const sortedResumes = response.data.resumeList.sort((a, b) => 
        new Date(b.createdAt) - new Date(a.createdAt)
      );
      
      setResumes(sortedResumes);
      setResumeCount(response.data.resumeCount);
      setUserProfileData(response.data.userProfileData);
      setError('');
    } catch (err) {
      console.error('Error fetching resumes:', err);
      setError('Failed to load resumes for this user. Please try again.');
      setResumes([]);
      setResumeCount(0);
    } finally {
      setLoading(false);
    }
  };

  // Fetch detailed resume data
  const fetchAndDownloadResume = async (userId, jobId) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        `${baseurl}${fetch_tailored_resume}`,
        { userid: userId, jobId },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      
      const resumeData = response.data.resume_data;
      
      const educationFormatted = userProfileData.education.map(edu => ({
        institution_name: edu.institutionName,
        degree: edu.degree,
        field_of_study: edu.fieldOfStudy,
        start_date: edu.startDate.substring(0, 10),
        end_date: edu.endDate.substring(0, 10)
      }));

      const sortItemsByStartDate = (items) => {
        return items.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
      };

      const experiencesSorted = sortItemsByStartDate(resumeData[0].response?.experiences || []);

      const projectsSorted = sortItemsByStartDate(resumeData
        .filter(item => {
          return typeof item === 'object' && item.response && Array.isArray(item.response.projects);
        })
        .reduce((acc, item) => {
          if (Array.isArray(item.response.projects)) {
            return acc.concat(item.response.projects.filter(project => typeof project === 'object'));
          }
          return acc;
        }, [])
      );

      const educationSorted = sortItemsByStartDate(educationFormatted);

      return {
        user_full_name: `${userProfileData.firstName} ${userProfileData.lastName}`,
        user_location: userProfileData.location_preference || '',
        user_phone: userProfileData.phone || '',
        user_email: userProfileData.email || '',
        user_linkedin: userProfileData.linkedin || '',
        portfolio_link: userProfileData.portfolio_link || '',
        github_link: userProfileData.github_link || '',
        experiences: experiencesSorted,
        projects: projectsSorted,
        education: educationSorted,
        certifications: userProfileData.certifications || [],
        generated_summary: resumeData[0].response?.generated_summary || '',
        skills: resumeData[0].response?.skills || {}
      };
    } catch (error) {
      console.error('Error fetching resume details:', error);
      throw error;
    }
  };

  const fetchAndPrepareDataForDownload = async (userId, jobId) => {
    try {
      setLoading(true);
      const data = await fetchAndDownloadResume(userId, jobId);
      setDownloadDataByJobId(prevState => ({
        ...prevState,
        [jobId]: {
          isReady: true,
          data,
        }
      }));
      if (!isModalOpen) {
        setIsModalOpen(true);
      }
    } catch (error) {
      setError('Error processing resume. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    const day = date.getDate();
    const daySuffix = ['st', 'nd', 'rd'][(day % 10) - 1] || 'th';
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear().toString().slice(-2);

    return `${formattedHours}:${formattedMinutes} ${ampm} ${day}${daySuffix} ${month} ${year}`;
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  const handleSelectUser = (user) => {
    setSelectedUser(user);
    fetchUserResumes(user.userid);
  };

  const handleClearSelection = () => {
    setSelectedUser(null);
    setResumes([]);
    setResumeCount(0);
  };
  
  // Handle opening the generate resume modal
  const handleOpenGenerateResumeModal = () => {
    setIsGenerateResumeModalOpen(true);
    setJobTitle('');
    setCompany('');
    setJobDescription('');
    setSkills('');
    setNewResumeData(null);
    setShowDownloadButton(false);
  };
  
  // Handle closing the generate resume modal
  const handleCloseGenerateResumeModal = () => {
    setIsGenerateResumeModalOpen(false);
    // Refresh user resumes list after generating a new resume
    if (newResumeData) {
      fetchUserResumes(selectedUser.userid);
    }
  };
  
  // Handle resume generation
  const handleGenerateResume = async () => {
    if (!selectedUser || !jobTitle.trim() || !company.trim() || !jobDescription.trim()) {
      return;
    }
    
    try {
      setGeneratingResume(true);
      setError('');
      
      const token = await getAccessTokenSilently();
      const url = baseurl + generate_tailored_resume;
      
      const payload = {
        userid: selectedUser.userid,
        job_title: jobTitle,
        job_description: jobDescription,
        company: company,
        source: "careerfornia",
        skills: skills || undefined
      };
      
      const response = await axios.post(url, payload, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      
      if (response.status === 200) {
        const data = response.data;

        // Ensure all arrays are properly formatted and invalid experiences are excluded
        data.experiences = Array.isArray(data.experiences) ? data.experiences.filter(exp => typeof exp === 'object' && exp !== null) : [];
        data.projects = Array.isArray(data.projects) ? data.projects : [];
        data.internships = Array.isArray(data.internships) ? data.internships : [];
        data.education = Array.isArray(data.education) ? data.education : [];
        data.certifications = Array.isArray(data.certifications) ? data.certifications : [];
        data.skills = typeof data.skills === 'object' && data.skills !== null ? data.skills : {};
        
        setNewResumeData(data);
        setShowDownloadButton(true);
      } else {
        throw new Error(response.data.error || 'An unexpected error occurred');
      }
    } catch (error) {
      console.error('Error generating tailored resume:', error);
      setError('Failed to generate resume: ' + (error.response?.data?.error || error.message || 'An unexpected error occurred'));
    } finally {
      setGeneratingResume(false);
    }
  };
  
  // Download generated resume
  const downloadResume = () => {
    try {
      generatePDF(newResumeData);
    } catch (error) {
      setError('Failed to download resume: ' + error.message);
    }
  };

  useEffect(() => {
    fetchLatestUsers();
  }, []);

  useEffect(() => {
    if (users && users.length > 0) {
      const filtered = users.filter(user => {
        const searchableText = `${user.username || ''} ${user.email || ''} ${user.userid || ''}`.toLowerCase();
        return searchableText.includes(searchTerm.toLowerCase());
      });
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers([]);
    }
  }, [searchTerm, users]);

  return (
    <Box>
      <Paper sx={{ p: 3, mb: 3, borderRadius: '10px' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 600, mb: 0 }}>
            User Resumes Management
          </Typography>
          <Tooltip title="Refresh Users List">
            <IconButton onClick={fetchLatestUsers} color="primary" disabled={usersLoading}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Box>
        
        <Typography variant="body2" color="text.secondary" mb={3}>
          Search and select a user to view their generated resumes.
        </Typography>

        <Box mb={3}>
          <TextField
            autoFocus
            margin="dense"
            id="search"
            label="Search Users"
            type="text"
            fullWidth
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: searchTerm && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear search"
                    onClick={clearSearch}
                    edge="end"
                    size="small"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              )
            }}
            sx={{ mb: 2 }}
          />

          {usersLoading ? (
            <Box display="flex" justifyContent="center" my={4}>
              <CircularProgress />
            </Box>
          ) : !selectedUser && filteredUsers.length > 0 ? (
            <Paper variant="outlined" sx={{ maxHeight: '300px', overflow: 'auto' }}>
              <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {filteredUsers.map((user) => (
                  <React.Fragment key={user.userid}>
                    <ListItem 
                      alignItems="flex-start"
                      button
                      onClick={() => handleSelectUser(user)}
                      sx={{
                        borderRadius: '8px',
                        '&:hover': {
                          bgcolor: 'rgba(0, 0, 0, 0.04)'
                        }
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <PersonIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle1"
                            component="span"
                            sx={{ fontWeight: 500 }}
                          >
                            {user.username || user.email || `User ID: ${user.userid}`}
                          </Typography>
                        }
                        secondary={
                          <>
                            <Typography
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              {user.email}
                            </Typography>
                            {/* Only show user ID, status column removed */}
                            {/* <Typography
                              component="span"
                              variant="body2"
                              color="text.secondary"
                              display="block"
                            >
                              ID: {user.userid}
                            </Typography> */}
                          </>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </React.Fragment>
                ))}
              </List>
            </Paper>
          ) : !selectedUser && !usersLoading ? (
            <Box textAlign="center" my={4}>
              <Typography variant="body1" color="text.secondary">
                {searchTerm ? 'No users found matching your search.' : 'No users available.'}
              </Typography>
            </Box>
          ) : null}

          {selectedUser && (
            <Stack direction="row" spacing={2} alignItems="center">
              <Paper
                elevation={0}
                sx={{
                  p: 2,
                  border: '1px solid',
                  borderColor: theme.palette.primary.light,
                  borderRadius: '8px',
                  bgcolor: theme.palette.primary.lighter || 'rgba(0, 0, 255, 0.05)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <Box>
                  <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                    {selectedUser.username || selectedUser.email || `User ID: ${selectedUser.userid}`}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    ID: {selectedUser.userid}
                  </Typography>
                </Box>
                <Stack direction="row" spacing={2}>
                  <Button 
                    size="small" 
                    variant="contained" 
                    color="primary"
                    startIcon={<DescriptionIcon />}
                    onClick={handleOpenGenerateResumeModal}
                  >
                    Generate Resume
                  </Button>
                  <Button 
                    size="small" 
                    variant="outlined" 
                    color="primary"
                    onClick={handleClearSelection}
                  >
                    Change
                  </Button>
                </Stack>
              </Paper>
            </Stack>
          )}
        </Box>
        
        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}
        
        {selectedUser && (
          <Box>
            <Divider sx={{ mb: 3 }} />
            <Typography variant="h6" gutterBottom>
              Generated Resumes: {resumeCount}
            </Typography>
            
            {loading ? (
              <Box display="flex" justifyContent="center" p={4}>
                <CircularProgress />
              </Box>
            ) : resumes.length > 0 ? (
              <Grid container spacing={2}>
                {resumes.map((resume, index) => (
                  <Grid item xs={12} key={resume.jobId}>
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3, delay: index * 0.05 }}
                    >
                      <Paper
                        elevation={0}
                        sx={{
                          p: 2,
                          border: '1px solid',
                          borderColor: 'divider',
                          borderRadius: '8px',
                          '&:hover': {
                            boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
                            borderColor: theme.palette.primary.main,
                          },
                          transition: 'all 0.2s ease-in-out'
                        }}
                      >
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Box>
                            <Typography variant="h6" sx={{ fontWeight: 500 }}>
                              {resume.jobTitle || 'Untitled Job'}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Generated: {formatDate(resume.createdAt)}
                            </Typography>
                            {resume.job_link && (
                              <Typography variant="body2" sx={{ mt: 1 }}>
                                <a 
                                  href={resume.job_link} 
                                  target="_blank" 
                                  rel="noopener noreferrer"
                                  style={{ 
                                    textDecoration: 'none', 
                                    color: theme.palette.primary.main,
                                    fontWeight: '500'
                                  }}
                                >
                                  View Job Listing
                                </a>
                              </Typography>
                            )}
                          </Box>
                          <Button 
                            variant="contained" 
                            color="primary"
                            onClick={() => fetchAndPrepareDataForDownload(selectedUser.userid, resume.jobId)}
                            disabled={loading}
                          >
                            View Resume
                          </Button>
                        </Box>
                      </Paper>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography variant="body1" color="text.secondary" sx={{ py: 4, textAlign: 'center' }}>
                No resumes found for this user.
              </Typography>
            )}
          </Box>
        )}
      </Paper>
      
      {/* Resume Details Modal */}
      {downloadDataByJobId[Object.keys(downloadDataByJobId)[0]]?.isReady && (
        <ResumeDataModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          data={downloadDataByJobId[Object.keys(downloadDataByJobId)[0]].data}
          jobId={Object.keys(downloadDataByJobId)[0]}
          userProfileData={userProfileData}
        />
      )}
      
      {/* Generate Resume Modal */}
      <Dialog 
        open={isGenerateResumeModalOpen} 
        onClose={handleCloseGenerateResumeModal}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          Generate Resume for {selectedUser?.username || selectedUser?.email || `User ID: ${selectedUser?.userid}`}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Job Title"
                  variant="outlined"
                  fullWidth
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                  required
                  margin="normal"
                  error={!jobTitle.trim() && jobTitle !== ''}
                  helperText={!jobTitle.trim() && jobTitle !== '' ? 'Job title is required' : ''}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Company"
                  variant="outlined"
                  fullWidth
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  required
                  margin="normal"
                  error={!company.trim() && company !== ''}
                  helperText={!company.trim() && company !== '' ? 'Company name is required' : ''}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Job Description"
                  variant="outlined"
                  fullWidth
                  value={jobDescription}
                  onChange={(e) => setJobDescription(e.target.value)}
                  required
                  multiline
                  rows={6}
                  margin="normal"
                  error={!jobDescription.trim() && jobDescription !== ''}
                  helperText={!jobDescription.trim() && jobDescription !== '' ? 'Job description is required' : ''}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Skills (optional)"
                  variant="outlined"
                  fullWidth
                  value={skills}
                  onChange={(e) => setSkills(e.target.value)}
                  helperText="Adding skills helps create a better tailored resume"
                  multiline
                  rows={2}
                  margin="normal"
                />
              </Grid>
            </Grid>
            
            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}
            
            {showDownloadButton && (
              <Box sx={{ mt: 3, textAlign: 'center' }}>
                <Alert severity="success" sx={{ mb: 2 }}>
                  Resume generated successfully!
                </Alert>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={downloadResume}
                  fullWidth
                >
                  Download PDF
                </Button>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseGenerateResumeModal}>
            Close
          </Button>
          <Button 
            onClick={handleGenerateResume} 
            color="primary" 
            variant="contained"
            disabled={generatingResume || !jobTitle.trim() || !company.trim() || !jobDescription.trim()}
          >
            {generatingResume ? <CircularProgress size={24} /> : 'Generate Resume'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserResumes; 