import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';

const AdminProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  
  if (isLoading) {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100vh',
          bgcolor: '#f8f9fa'
        }}
      >
        <CircularProgress size={60} />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Checking authentication...
        </Typography>
      </Box>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  // Admin check is bypassed for local development
  // The commented code below can be uncommented when ready to implement admin restrictions
  /*
  if (!isAdmin) {
    return (
      <Container maxWidth="sm" sx={{ mt: 10 }}>
        <Paper 
          sx={{ 
            p: 4, 
            borderRadius: '10px',
            textAlign: 'center',
            boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 2px 0px',
            border: '1px solid rgba(0, 0, 0, 0.1)'
          }}
        >
          <Typography variant="h4" gutterBottom>
            Access Denied
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            You don't have permission to access this page. This area is restricted to administrators only.
          </Typography>
          <Typography variant="body2" color="text.secondary">
            If you believe this is an error, please contact the system administrator.
          </Typography>
        </Paper>
      </Container>
    );
  }
  */

  return children;
};

export default AdminProtectedRoute; 