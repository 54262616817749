import React, { useState, useEffect } from 'react';
import TailoredResume from '../components/dashboard/tailored_resume';
import { Typography, Grid, useTheme, CircularProgress, Box, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import '../styles/components/dashboard.css';
import { UserProvider } from '../components/user_context';
import { useAuth0 } from '@auth0/auth0-react';
import { baseurl, check_credits, is_user_onboarded } from '../util/endpoints.js';
import OnboardUser from './onboard_user';

const Dashboard = () => {
    const theme = useTheme();
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [usagecredits, setUsagecredits] = useState({
        auto_apply_credits: 0,
        auto_apply_limit: 0,
        ai_resume_credits: 0,
        ai_resume_limit: 0,
        ai_post_comment_credits: 0,
        ai_post_comment_limit: 0,
    });
    const [isOnboarded, setIsOnboarded] = useState(null);
    const userid = localStorage.getItem('userid');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            if (user) {
                setIsLoading(true);
                try {
                    const token = await getAccessTokenSilently();
                    
                    // Check onboarding status
                    const onboardingResponse = await fetch(baseurl + is_user_onboarded, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ userid: userid }),
                    });
                    const onboardingData = await onboardingResponse.json();
                    setIsOnboarded(onboardingData.is_onboarded);

                    // Fetch credits if user is onboarded
                    if (onboardingData.is_onboarded) {
                        const creditsResponse = await fetch(baseurl + check_credits, {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ userid: userid }),
                        });
                        const creditsData = await creditsResponse.json();
                        if (creditsData.status === 'success') {
                            setUsagecredits(creditsData.data);
                        }
                    }
                } catch (error) {
                    console.error("Failed to fetch data:", error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchData();
    }, [user, getAccessTokenSilently, userid]);

    if (isLoading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <CircularProgress 
                    size={60}
                    thickness={4}
                    sx={{
                        color: theme.palette.primary.main,
                    }}
                />
            </Box>
        );
    }

    return (
        <UserProvider>
            {isOnboarded ? (
                <div className="dashboard-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: '16px' }}>
                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid item xs={12} sx={{ textAlign: 'center', mb: 4 }}>
                            <Typography variant="h5" sx={{
                                fontWeight: 'bold',
                                color: theme.palette.primary.main,
                                mb: 2
                            }}>
                                Generate AI Resume
                            </Typography>
                            <Typography variant="body1" sx={{
                                color: theme.palette.text.secondary,
                                mb: 3
                            }}>
                                Copy and paste the job description below to generate a resume
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <TailoredResume />
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'center', marginTop: '24px', padding: '16px', backgroundColor: '#f0f8ff', borderRadius: '8px', border: '1px solid #fff' }}>
                            <Typography sx={{
                                fontSize: { xs: '1rem', sm: '1.125rem' },
                                fontFamily: 'Georgia, serif',
                                color: theme.palette.primary.main,
                                fontWeight: 'bold',
                                marginBottom: '12px',
                            }}>
                                ✨ Supercharge Your LinkedIn Job Applications! ✨
                            </Typography>
                            <Typography sx={{
                                fontSize: { xs: '0.875rem', sm: '1rem' },
                                fontFamily: 'Georgia, serif',
                                color: theme.palette.text.secondary,
                                marginBottom: '16px',
                            }}>
                                Generate tailored resumes in less than 5 seconds with a single click using our powerful Chrome extension!
                            </Typography>
                            <Button 
                                variant="contained" 
                                color="primary"
                                href="https://chromewebstore.google.com/detail/careerfonia/ampdncancbacehgbghpfhgfllclcinnp"
                                target="_blank"
                                rel="noopener noreferrer"
                                size="large"
                                startIcon={<AddCircleOutlineIcon />}
                                sx={{ 
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    padding: '8px 16px',
                                    '&:hover': {
                                        backgroundColor: theme.palette.primary.dark,
                                    }
                                }}
                            >
                                Add Chrome Extension
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            ) : (
                <OnboardUser />
            )}
        </UserProvider>
    );
};

export default Dashboard;
