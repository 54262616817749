import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { FaRegSmile } from 'react-icons/fa';
import confetti from 'canvas-confetti';

// Styled Components
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const WelcomeAlertContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to right, #6a11cb, #2575fc);
  animation: ${fadeIn} 1.5s ease-in;
`;

const AlertContent = styled.div`
  text-align: center;
  background: white;
  padding: 40px 50px;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  animation: ${fadeIn} 2s ease-in;
`;

const Title = styled.h1`
  font-family: 'Poppins', sans-serif;
  color: #333;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Message = styled.p`
  font-family: 'Roboto', sans-serif;
  color: #555;
  margin-bottom: 30px;
  font-size: 1.1em;
`;

const StyledButton = styled.button`
  background: #2575fc;
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 25px;
  font-size: 1em;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #6a11cb;
  }
`;

const CounterText = styled.p`
  font-family: 'Roboto', sans-serif;
  color: #777;
  margin-top: 20px;
  font-size: 1em;
`;

const WelcomeAlert = () => {
    const navigate = useNavigate();
    const [counter, setCounter] = useState(30);

    useEffect(() => {
        const timer = setInterval(() => {
            setCounter((prevCounter) => prevCounter - 1);
        }, 1000);

        if (counter === 0) {
            navigate('../dashboard-layout');
        }

        return () => clearInterval(timer);
    }, [counter, navigate]);

    useEffect(() => {
        const end = Date.now() + (15 * 1000);

        const colors = ['#bb0000', '#ffffff'];

        (function frame() {
            confetti({
                particleCount: 2,
                angle: 60,
                spread: 55,
                origin: { x: 0 },
                colors: colors
            });
            confetti({
                particleCount: 2,
                angle: 120,
                spread: 55,
                origin: { x: 1 },
                colors: colors
            });

            if (Date.now() < end) {
                requestAnimationFrame(frame);
            }
        }());
    }, []);

    return (
        <WelcomeAlertContainer>
            <AlertContent>
                <Title><FaRegSmile style={{ marginRight: '10px' }} /> Welcome Aboard!</Title>
                <Message>You are all set to get the interview calls</Message>
                <StyledButton style={{ backgroundColor: '#0077B5' }} onClick={() => navigate('../dashboard-layout')}>Go to Dashboard</StyledButton>
                <CounterText>Redirecting in {counter} seconds...</CounterText>
            </AlertContent>
        </WelcomeAlertContainer>
    );
};

export default WelcomeAlert;