import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  Grid,
  CircularProgress,
  Divider,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  LinearProgress,
  Tooltip,
  Collapse,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  useMediaQuery,
  Link
} from '@mui/material';
import {
  CheckCircleOutline,
  ErrorOutline,
  ContentPaste,
  Work,
  Compare,
  ExpandMore,
  ExpandLess,
  InfoOutlined,
  Assignment,
  Assessment,
  School,
  FormatListBulleted,
  CheckCircle,
  Language,
  PersonOutline,
  Description,
  LocalOffer,
  PlaylistAdd
} from '@mui/icons-material';
import axios from 'axios';
import { baseurl} from '../../util/endpoints.js';
import { useAuth0 } from '@auth0/auth0-react';


const ATSScoreChecker = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { getAccessTokenSilently, user } = useAuth0();
  
  // State for input fields
  const [resume, setResume] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  
  // State for analysis results
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [error, setError] = useState('');
  
  // State for collapsible sections
  const [expandedSections, setExpandedSections] = useState({
    keywordMatch: true,
    skillsMatch: true,
    experienceRelevance: true,
    educationMatch: true,
    resumeFormat: true,
    contentQuality: true,
    overallCompatibility: true
  });

  // Show an info message when the component mounts
  useEffect(() => {
    setError('');
  }, []);

  // Toggle section expansion
  const toggleSection = (section) => {
    setExpandedSections({
      ...expandedSections,
      [section]: !expandedSections[section]
    });
  };

  // Calculate total score from all categories
  const calculateTotalScore = (results) => {
    if (!results) return 0;
    const { 
      keywordMatch, 
      skillsMatch,
      experienceRelevance,
      educationMatch,
      resumeFormat,
      contentQuality,
      overallCompatibility 
    } = results;
    
    // Sum all category scores (each out of 10, total out of 70)
    const rawScore = keywordMatch.score + 
           skillsMatch.score + 
           experienceRelevance.score + 
           educationMatch.score + 
           resumeFormat.score + 
           contentQuality.score + 
           overallCompatibility.score;
    
    // Convert to percentage score (out of 100)
    return (rawScore / 70) * 100;
  };

  // Determine color based on score
  const getScoreColor = (score, max = 10) => {
    const percentage = (score / max) * 100;
    if (percentage >= 80) return theme.palette.success.main;
    if (percentage >= 60) return theme.palette.warning.main;
    return theme.palette.error.main;
  };

  // Format percentage for display
  const formatPercentage = (score, max = 10) => {
    return Math.round((score / max) * 100);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!resume.trim() || !jobDescription.trim()) {
      setError('Both resume and job description are required');
      return;
    }
    
    setLoading(true);
    setError('');
    setResults(null);
    
    try {
      // Get Auth0 token
      const token = await getAccessTokenSilently();
      
      // Call backend API endpoint with token
      const response = await axios.post(
        `${baseurl}/api/analyze-resume-ats`,
        {
          resume,
          jobDescription,
          userid: user?.sub
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );
      
      // Set results from the response
      setResults(response.data);
    } catch (error) {
      console.error('Error analyzing resume:', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('API Error Response:', error.response.data);
        console.error('Status:', error.response.status);
        setError(`Error (${error.response.status}): ${error.response.data.message || 'Unknown error'}`);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received:', error.request);
        setError('No response received from the server. Please check your internet connection and try again.');
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Request error:', error.message);
        setError(`Error: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  // Render a score card for a category
  const renderScoreCard = (category, title, icon) => {
    if (!results) return null;
    
    const categoryData = results[category];
    const score = categoryData.score;
    
    return (
      <Card 
        variant="outlined" 
        sx={{ 
          mb: 2, 
          borderRadius: '10px',
          boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
          overflow: 'visible'
        }}
      >
        <CardHeader
          avatar={icon}
          title={
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6" component="div">
                {title}
              </Typography>
              <Box display="flex" alignItems="center">
                <Chip 
                  label={`${score}/10`} 
                  sx={{ 
                    backgroundColor: getScoreColor(score),
                    color: 'white',
                    fontWeight: 'bold',
                    mr: 1
                  }}
                />
                <IconButton 
                  size="small" 
                  onClick={() => toggleSection(category)}
                  aria-expanded={expandedSections[category]}
                  aria-label="show more"
                >
                  {expandedSections[category] ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Box>
            </Box>
          }
          sx={{
            backgroundColor: theme.palette.grey[50],
            borderBottom: `1px solid ${theme.palette.grey[200]}`
          }}
        />
        <Collapse in={expandedSections[category]} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography variant="body2" paragraph>
              {categoryData.explanation}
            </Typography>
            
            <Typography variant="subtitle2" gutterBottom sx={{ fontWeight: 'bold', mt: 2 }}>
              Recommendations:
            </Typography>
            
            <List dense sx={{ pl: 2 }}>
              {categoryData.recommendations.map((rec, index) => (
                <ListItem key={index} sx={{ py: 0.5 }}>
                  <ListItemIcon sx={{ minWidth: 30 }}>
                    <CheckCircleOutline color="primary" fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={rec} />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Collapse>
      </Card>
    );
  };

  // Render the skills comparison table
  const renderSkillsComparisonTable = () => {
    if (!results || !results.skillsComparison) return null;
    
    return (
      <TableContainer component={Paper} variant="outlined" sx={{ mt: 3, borderRadius: '10px', overflow: 'hidden' }}>
        <Table size="small">
          <TableHead sx={{ backgroundColor: theme.palette.grey[100] }}>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Skill/Keyword</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold' }}>Resume Presence</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold' }}>Job Importance</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold' }}>Match</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.skillsComparison.map((item, index) => {
              const matchScore = Math.min(item.resumePresence, item.jobImportance);
              return (
                <TableRow key={index} hover>
                  <TableCell component="th" scope="row">
                    {item.skill}
                  </TableCell>
                  <TableCell align="center">
                    <LinearProgress 
                      variant="determinate" 
                      value={item.resumePresence * 10} 
                      sx={{ 
                        height: 10, 
                        borderRadius: 5,
                        width: '90%',
                        mx: 'auto',
                        backgroundColor: theme.palette.grey[200],
                        '& .MuiLinearProgress-bar': {
                          backgroundColor: getScoreColor(item.resumePresence)
                        }
                      }}
                    />
                    <Typography variant="caption" display="block" textAlign="center" mt={0.5}>
                      {item.resumePresence}/10
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <LinearProgress 
                      variant="determinate" 
                      value={item.jobImportance * 10} 
                      sx={{ 
                        height: 10, 
                        borderRadius: 5,
                        width: '90%',
                        mx: 'auto',
                        backgroundColor: theme.palette.grey[200],
                        '& .MuiLinearProgress-bar': {
                          backgroundColor: theme.palette.primary.main
                        }
                      }}
                    />
                    <Typography variant="caption" display="block" textAlign="center" mt={0.5}>
                      {item.jobImportance}/10
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Chip 
                      label={`${formatPercentage(matchScore, 10)}%`} 
                      size="small"
                      sx={{ 
                        backgroundColor: getScoreColor(matchScore),
                        color: 'white',
                        fontWeight: 'bold'
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box>
      <Paper 
        sx={{ 
          p: 3, 
          borderRadius: '10px',
          boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 2px 0px',
          border: '1px solid rgba(0, 0, 0, 0.1)',
          mb: 4
        }}
      >
        <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 600 }}>
          ATS Score Checker
        </Typography>
        <Typography variant="body2" color="text.secondary" paragraph>
          Analyze resumes against job descriptions to calculate ATS compatibility scores and get detailed improvement recommendations.
        </Typography>
        
        {/* <Box sx={{ mb: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            startIcon={<PlaylistAdd />}
            onClick={loadSampleData}
            color="secondary"
            variant="outlined"
            size="small"
          >
            Load Sample Data
          </Button>
        </Box> */}
        
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Resume Text"
                multiline
                rows={12}
                fullWidth
                variant="outlined"
                value={resume}
                onChange={(e) => setResume(e.target.value)}
                placeholder="Paste resume content here..."
                InputProps={{
                  startAdornment: (
                    <Box
                      sx={{
                        position: 'absolute',
                        right: 10,
                        top: 10,
                        zIndex: 1,
                      }}
                    >
                      <Tooltip title="Paste resume content">
                        <IconButton 
                          size="small"
                          color="primary"
                          onClick={() => navigator.clipboard.readText().then(text => setResume(text))}
                        >
                          <ContentPaste fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ),
                }}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Job Description"
                multiline
                rows={12}
                fullWidth
                variant="outlined"
                value={jobDescription}
                onChange={(e) => setJobDescription(e.target.value)}
                placeholder="Paste job description here..."
                InputProps={{
                  startAdornment: (
                    <Box
                      sx={{
                        position: 'absolute',
                        right: 10,
                        top: 10,
                        zIndex: 1,
                      }}
                    >
                      <Tooltip title="Paste job description">
                        <IconButton 
                          size="small"
                          color="primary"
                          onClick={() => navigator.clipboard.readText().then(text => setJobDescription(text))}
                        >
                          <ContentPaste fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ),
                }}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={loading}
                sx={{
                  py: 1.5,
                  px: 4,
                  borderRadius: '8px',
                  fontWeight: 600,
                }}
              >
                {loading ? (
                  <>
                    <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                    Analyzing...
                  </>
                ) : (
                  'Analyze ATS Compatibility'
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>

      {error && (
        <Alert 
          severity={error.includes('Sample data loaded') ? "info" : "error"} 
          sx={{ mb: 3 }}
          onClose={() => setError('')}
        >
          {error}
        </Alert>
      )}

      {results && (
        <Box sx={{ mt: 3 }}>
          <Paper 
            elevation={0}
            sx={{ 
              p: 3, 
              borderRadius: '10px',
              boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 2px 0px',
              border: '1px solid rgba(0, 0, 0, 0.1)',
              mb: 4,
              backgroundImage: 'linear-gradient(120deg, #f6f9fc 0%, #f2f6f9 100%)'
            }}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6}>
                <Box>
                  <Typography variant="h4" component="h2" sx={{ fontWeight: 700 }}>
                    Overall ATS Score
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Based on analysis of 7 compatibility factors (100-point scale)
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                  <Box
                    sx={{
                      position: 'relative',
                      display: 'inline-flex',
                      mr: 2
                    }}
                  >
                    <CircularProgress
                      variant="determinate"
                      value={calculateTotalScore(results) / 100 * 100}
                      size={80}
                      thickness={6}
                      sx={{
                        color: getScoreColor(calculateTotalScore(results), 100),
                        backgroundColor: theme.palette.grey[200],
                        borderRadius: '50%'
                      }}
                    />
                    <Box
                      sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        variant="h6"
                        component="div"
                        color="text.primary"
                        fontWeight="bold"
                      >
                        {Math.round(calculateTotalScore(results) / 100 * 100)}%
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography variant="h6" fontWeight="bold" color={getScoreColor(calculateTotalScore(results), 100)}>
                      {Math.round(calculateTotalScore(results))}/100
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      Total Points
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Paper>

          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Box mb={3}>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 600, mb: 2 }}>
                  Detailed Score Breakdown
                </Typography>
                
                {renderScoreCard('keywordMatch', 'Keyword Match', <LocalOffer color="primary" />)}
                {renderScoreCard('skillsMatch', 'Skills Match', <Assessment color="primary" />)}
                {renderScoreCard('experienceRelevance', 'Experience Relevance', <Work color="primary" />)}
                {renderScoreCard('educationMatch', 'Education Match', <School color="primary" />)}
                {renderScoreCard('resumeFormat', 'Resume Format & Structure', <FormatListBulleted color="primary" />)}
                {renderScoreCard('contentQuality', 'Content Quality', <Description color="primary" />)}
                {renderScoreCard('overallCompatibility', 'Overall Compatibility', <PersonOutline color="primary" />)}
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box mb={3}>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 600, mb: 2 }}>
                  Missing Keywords
                </Typography>
                <Paper
                  variant="outlined"
                  sx={{
                    p: 2,
                    borderRadius: '10px',
                  }}
                >
                  {results.missingKeywords && results.missingKeywords.length > 0 ? (
                    <Box>
                      <Alert 
                        severity="warning" 
                        sx={{ mb: 2 }}
                        icon={<InfoOutlined />}
                      >
                        Adding these keywords could significantly improve your ATS score
                      </Alert>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {results.missingKeywords.map((keyword, index) => (
                          <Chip
                            key={index}
                            label={keyword}
                            variant="outlined"
                            color="warning"
                            size="small"
                            sx={{ margin: '4px' }}
                          />
                        ))}
                      </Box>
                    </Box>
                  ) : (
                    <Alert severity="success">
                      Great job! No critical keywords are missing.
                    </Alert>
                  )}
                </Paper>
              </Box>
              
              <Box mb={3}>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 600, mb: 2 }}>
                  Quick Tips
                </Typography>
                <Paper
                  variant="outlined"
                  sx={{
                    p: 2,
                    borderRadius: '10px',
                  }}
                >
                  <List dense disablePadding>
                    <ListItem disableGutters>
                      <ListItemIcon sx={{ minWidth: 30 }}>
                        <CheckCircle fontSize="small" color="success" />
                      </ListItemIcon>
                      <ListItemText 
                        primary="Use exact keywords from the job description" 
                        secondary="ATS systems look for exact matches"
                      />
                    </ListItem>
                    <Divider component="li" sx={{ my: 1 }} />
                    <ListItem disableGutters>
                      <ListItemIcon sx={{ minWidth: 30 }}>
                        <CheckCircle fontSize="small" color="success" />
                      </ListItemIcon>
                      <ListItemText 
                        primary="Keep formatting simple" 
                        secondary="Avoid tables, headers, footers, and images"
                      />
                    </ListItem>
                    <Divider component="li" sx={{ my: 1 }} />
                    <ListItem disableGutters>
                      <ListItemIcon sx={{ minWidth: 30 }}>
                        <CheckCircle fontSize="small" color="success" />
                      </ListItemIcon>
                      <ListItemText 
                        primary="Include both acronyms and full terms" 
                        secondary="e.g., 'UI/UX' and 'User Interface/User Experience'"
                      />
                    </ListItem>
                  </List>
                </Paper>
              </Box>
            </Grid>
          </Grid>

          <Typography variant="h6" gutterBottom sx={{ fontWeight: 600, mb: 2, mt: 4 }}>
            Skills Comparison
          </Typography>
          {renderSkillsComparisonTable()}
        </Box>
      )}
    </Box>
  );
};

export default ATSScoreChecker; 