// export const baseurl = "http://localhost:25060";
export const baseurl = "https://api.careerfornia.com";

export const save_user_details = "/api/save-user-details";
export const upload_resume = "/api/upload_resume/";
export const check_credits = "/api/credit-check/";
export const user_details = "/api/user-details/";
export const save_user_preferences_v2 = "/api/save-user-preferences-v2";
export const is_user_onboarded = "/api/check-onboarded/";
export const generate_tailored_resume = "/api/generate-tailored-data/";
export const onboard_user = "/api/onboard-user/";
export const check_onboarded_status = "/api/check-onboarded";
export const resumes_list = "/api/resumes/list"
export const save_user_resume_details = "/api/save-user-resume-details";
export const trial_post = "/api/trial-post";
export const get_resume_data = "/api/get-resume-data";
export const fetch_tailored_resume = "/api/fetch-tailored-resume";
export const stripeEndpoint = "/api/create-checkout-session";
export const supportMail = "/api/send-support-email";
export const getAppliedJobs = "/api/appliedJobsList";
export const save_interview_conversation = "/api/save-conversation";
export const stream_anthropic = "/api/stream-responses";

// Admin API endpoints
export const admin_get_users = "/api/admin/users";
export const admin_get_user = "/api/admin/users/";
export const admin_delete_user = "/api/admin/users/";
export const admin_get_user_resumes = "/api/admin/users/resumes/";