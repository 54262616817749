import React, { useState, useEffect } from 'react';
import { Box, Drawer, List, ListItem, ListItemText, Typography, Grid, Skeleton, IconButton, useTheme, useMediaQuery, Link, CircularProgress, Chip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Dashboard from './pages/dashboard';
import Pricing from './components/homepage/pricing';
import JobsApplied from './components/dashboard/jobs_applied';
import ReportIssue from './components/dashboard/report_issue';
import TailoredResume from './components/dashboard/tailored_resume';
import FAQPage from './pages/faq';
import Support from './components/dashboard/support';
import Announcements from './components/dashboard/announcements';
import CareerHacks from './components/career_hacks';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { baseurl, is_user_onboarded, check_credits } from './util/endpoints';
import GeneratedResumes from './components/dashboard/generated_resumes';
import ResumePage from './pages/resume_page';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import HowExtensionWorks from './pages/how_extension_works';
import ATSScoreChecker from './components/admin/ATSScoreChecker.js'

const drawerWidth = 240;
const topNavBarHeight = 64;

const DashboardLayout = () => {
    const [selectedItem, setSelectedItem] = useState('dashboard');
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const [usagecredits, setUsagecredits] = useState({
        auto_apply_credits: 0,
        auto_apply_limit: 0,
        ai_resume_credits: 0,
        ai_resume_limit: 0,
        ai_post_comment_credits: 0,
        ai_post_comment_limit: 0,
    });
    const [aiResumeGenerationCount, setAiResumeGenerationCount] = useState({ generated: 2, limit: 10 });
    const navigate = useNavigate();
    const userid = localStorage.getItem('userid');
    const [loading, setLoading] = useState(true);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isOnboarded, setIsOnboarded] = useState(null);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        if (isAuthenticated) {
            const fetchUsagecredits = async () => {
                if (userid) {
                    setLoading(true);
                    try {
                        const token = await getAccessTokenSilently();
                        const endpoint = baseurl + check_credits;
                        const response = await fetch(endpoint, {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ userid: userid }),
                        });
                        const data = await response.json();
                        if (data.status === 'success') {
                            setUsagecredits({
                                auto_apply_credits: data.data.auto_apply_credits,
                                auto_apply_limit: data.data.auto_apply_limit,
                                ai_resume_credits: data.data.ai_resume_credits,
                                ai_resume_limit: data.data.ai_resume_limit,
                                ai_post_comment_credits: data.data.ai_post_comment_credits,
                                ai_post_comment_limit: data.data.ai_post_comment_limit,
                            });
                        } else if (data.status === 'error' && data.message === 'User not found') {
                            console.log('User not found, redirecting to onboarding page');
                            navigate('/onboard-user');
                        } else {
                            console.error('Error fetching usage credits:', data);
                        }
                    } catch (error) {
                        console.error('Error fetching usage credits:', error);
                        // If there's a network error or other exception, we should still set loading to false
                    } finally {
                        setLoading(false);
                    }
                } else {
                    // If there's no userid, we should still set loading to false
                    setLoading(false);
                }
            };

            fetchUsagecredits();
        }
    }, [user, isAuthenticated, userid, navigate, getAccessTokenSilently]);

    useEffect(() => {
        if (isAuthenticated) {
            const checkOnboardedStatus = async () => {
                setLoading(true);
                try {
                    const token = await getAccessTokenSilently();
                    const url = baseurl + is_user_onboarded;
                    const response = await axios.post(url, { userid: userid },
                        {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json'
                            }
                        });
                    
                    if (response.data && response.data.is_onboarded !== undefined) {
                        setIsOnboarded(response.data.is_onboarded);
                        
                        // If user is not onboarded, redirect to onboarding page
                        if (!response.data.is_onboarded) {
                            console.log('User not onboarded, redirecting to onboarding page');
                            navigate('/onboard-user');
                        }
                    } else {
                        console.error('Invalid response format from onboarding check:', response.data);
                    }
                } catch (error) {
                    console.error('Error checking onboarding status:', error);
                    
                    // If the error is due to user not found, redirect to onboarding
                    if (error.response && 
                        error.response.data && 
                        error.response.data.status === 'error' && 
                        error.response.data.message === 'User not found') {
                        console.log('User not found, redirecting to onboarding page');
                        navigate('/onboard-user');
                    }
                } finally {
                    // Always set loading to false, regardless of success or failure
                    setLoading(false);
                }
            };
            checkOnboardedStatus();
        }
    }, [isAuthenticated, userid, navigate, getAccessTokenSilently]);

    useEffect(() => {
        // If authentication is complete (not loading) but user is not authenticated
        // we should set our loading state to false
        if (!isLoading && !isAuthenticated) {
            setLoading(false);
        }
    }, [isLoading, isAuthenticated]);

    if (loading) {
        if (isAuthenticated) {
            return (
                <Box sx={{ display: 'flex' }}>
                    {/* Mobile menu button skeleton */}
                    {isMobile && (
                        <Box sx={{ p: 2 }}>
                            <Skeleton variant="circular" width={40} height={40} animation="wave" />
                        </Box>
                    )}
                    
                    {/* Skeleton for sidebar - hidden on mobile unless mobileOpen is true */}
                    <Box sx={{ 
                        width: drawerWidth,
                        flexShrink: 0,
                        display: { xs: 'none', sm: 'block' }
                    }}>
                        <Box sx={{ 
                            width: drawerWidth, 
                            boxSizing: 'border-box',
                            mt: `${topNavBarHeight}px`,
                            height: '100vh',
                            borderRight: '1px solid rgba(0, 0, 0, 0.12)'
                        }}>
                            {/* Menu items skeletons */}
                            {Array.from(new Array(7)).map((_, index) => (
                                <Skeleton 
                                    key={index}
                                    variant="text" 
                                    width="80%" 
                                    height={50}
                                    animation="wave" 
                                    sx={{ mx: 2, my: 1 }}
                                />
                            ))}
                            
                            {/* Credits section skeleton */}
                            <Box sx={{ 
                                mt: 'auto', 
                                mb: 10, 
                                px: 2, 
                                py: 1,
                                borderTop: 1,
                                borderColor: 'divider',
                            }}>
                                {Array.from(new Array(3)).map((_, index) => (
                                    <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
                                        <Skeleton variant="text" width="60%" height={24} animation="wave" />
                                        <Skeleton variant="text" width="20%" height={24} animation="wave" />
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                    
                    {/* Skeleton for main content */}
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            p: 3,
                            width: { sm: `calc(100% - ${drawerWidth}px)` },
                            marginTop: `${topNavBarHeight}px`,
                        }}
                    >
                        {/* Title skeleton */}
                        <Skeleton variant="text" width="60%" height={40} animation="wave" sx={{ mb: 4 }} />
                        
                        {/* Content skeletons - responsive layout */}
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Skeleton variant="rectangular" width="100%" height={200} animation="wave" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Skeleton variant="rectangular" width="100%" height={200} animation="wave" />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton variant="rectangular" width="100%" height={150} animation="wave" />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            );
        }
    }

    const renderContent = () => {
        switch (selectedItem) {
            case 'dashboard':
                return <Dashboard />;
            case 'jobs-applied':
                return <JobsApplied />;
            case 'generated-resumes':
                return <GeneratedResumes />;
            // case 'pricing':
            //     return <Pricing />;
            case 'generate-ai-resume':
                return <TailoredResume />;
            // case 'purchase-credit':
            //     return <Pricing />;
            case 'faq':
                return <FAQPage />;
            case 'support':
                return <Support />;
            case 'announcements':
                return <Announcements />;
            case 'career-hacks':
                return <CareerHacks />;
            case 'how-extension-works':
                return <HowExtensionWorks />;
            case 'ats-score':
                return <ATSScoreChecker />;
            default:
                return <Dashboard />;
        }
    };

    if (isLoading) return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            <CircularProgress 
                size={60}
                thickness={4}
                sx={{
                    color: theme.palette.primary.main,
                }}
            />
        </Box>
    );

    if (!isAuthenticated) {
        return <Navigate to="/" />;
    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                {isMobile && (
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, ...(isMobile ? { display: 'block' } : { display: 'none' }) }}>
                        <MenuIcon />
                    </IconButton>
                )}
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            mt: `${topNavBarHeight}px`,
                            display: 'flex',
                            flexDirection: 'column',
                        },
                        zIndex: theme.zIndex.drawer
                    }}
                    variant={isMobile ? "temporary" : "permanent"}
                    open={isMobile ? mobileOpen : true}
                    onClose={handleDrawerToggle}
                >
                    <List sx={{ overflowY: 'auto', flex: 1 }}>
                        <ListItem
                            button
                            key="Dashboard"
                            onClick={() => setSelectedItem('dashboard')}
                            sx={{
                                fontSize: { xs: '1.25rem', sm: '1.5rem' },
                                fontFamily: 'Georgia, serif',
                                margin: '0 auto',
                                textAlign: 'start',
                                fontWeight: 'bold',
                                marginBottom: '16px',
                            }}
                        >
                            <ListItemText primary="Dashboard" />
                        </ListItem>
                        
                        <ListItem
                            button
                            key="ATS Score"
                            onClick={() => setSelectedItem('ats-score')}
                            sx={{
                                fontSize: { xs: '1.25rem', sm: '1.5rem' },
                                fontFamily: 'Georgia, serif',
                                margin: '0 auto',
                                textAlign: 'start',
                                fontWeight: 'bold',
                                marginBottom: '16px',
                            }}
                        >
                            <ListItemText primary="ATS Score" />
                            <Chip 
                                label="NEW" 
                                size="small" 
                                color="primary"
                                sx={{ 
                                    fontWeight: 'bold',
                                    fontSize: '0.7rem',
                                    height: '22px',
                                    ml: 1
                                }} 
                            />
                        </ListItem>
                        
                        {['Generated Resumes', 'Purchase Credit', 'FAQ', 'Support', 'Career Hacks', 'How Extension Works'].map((text) => (
                            <ListItem
                                button
                                key={text}
                                onClick={() => setSelectedItem(text.toLowerCase().replace(/\s/g, '-'))}
                                sx={{
                                    fontSize: { xs: '1.25rem', sm: '1.5rem' },
                                    fontFamily: 'Georgia, serif',
                                    margin: '0 auto',
                                    textAlign: 'start',
                                    fontWeight: 'bold',
                                    marginBottom: '16px',
                                }}
                            >
                                <ListItemText primary={text} />
                            </ListItem>
                        ))}
                    </List>

                    <Grid item xs={4} sx={{ textAlign: 'center', paddingBottom: '20px', position: 'relative' }}>

                        <Typography
                            variant="body2"
                            sx={{
                                mt: 1,
                                cursor: 'pointer',
                                backgroundColor: 'lightblue',
                                padding: '10px',
                                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                                transition: 'transform 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                },
                            }}
                            onClick={() => window.open('https://chromewebstore.google.com/detail/careerfonia/ampdncancbacehgbghpfhgfllclcinnp', '_blank', 'noopener')}
                        >
                            🎉 Download Extension 🎉
                        </Typography>
                    </Grid>

                    <Box
                        sx={{
                            mt: 'auto',
                            mb: 10,
                            px: 2,
                            py: 1,
                            backgroundColor: 'background.default',
                            borderTop: 1,
                            borderColor: 'divider',
                        }}
                    >
                        <Grid container alignItems="center">
                            <Grid item xs={8}>
                                <Typography variant="body2" fontWeight="bold">
                                    Available Credits
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ textAlign: 'right' }}>
                                <Typography variant="body2" fontWeight="bold">
                                    {usagecredits.ai_resume_credits}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
                                    1 Resume Generation = 1 Credit
                                </Typography>
                                <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                                    1 ATS Score Check = 1 Credit
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        marginTop: `20px`,
                    }}
                >
                    {/* ATS Score Checker Announcement Banner */}
                    <Box
                        sx={{
                            width: '100%',
                            p: 2,
                            mb: 3,
                            bgcolor: '#f0f7ff',
                            color: '#1976d2',
                            borderRadius: '4px',
                            boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                            textAlign: 'center',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                                bgcolor: '#e1f0ff',
                                boxShadow: '0 2px 5px rgba(0,0,0,0.15)',
                            },
                        }}
                    >
                        <Typography 
                            variant="body1" 
                            fontWeight="medium"
                            sx={{
                                fontSize: { xs: '0.875rem', sm: '1rem' },
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            ✨ Introducing ATS Score: Scan your resume to check your score and identify tips to improve your resume. Try it now! ✨
                        </Typography>
                    </Box>
                    {renderContent()}
                </Box>
            </Box>
        </>
    );
};

export default DashboardLayout;