import React, { useState, useRef, useEffect } from 'react';
import { createClient, LiveTranscriptionEvents } from '@deepgram/sdk';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Typography,
  Button,
  Paper,
  TextField,
  ThemeProvider,
  createTheme,
  IconButton,
  Chip,
  Divider,
  CircularProgress,
  Container,
  Card,
  CardContent,
  Grid,
  Stack,
  Avatar,
  useMediaQuery,
  Popover,
  Tooltip
} from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import RefreshIcon from '@mui/icons-material/Refresh';
import SendIcon from '@mui/icons-material/Send';
import PersonIcon from '@mui/icons-material/Person';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InfoIcon from '@mui/icons-material/Info';
import { flushSync } from 'react-dom';
import { dataEngineerPrompt, sdetQAPrompt, dotNetPrompt } from './prompt';
import { baseurl, save_interview_conversation, stream_anthropic } from '../../util/endpoints';
import InterviewModal from './InterviewModal';

// Create a custom theme for the interview component
const interviewTheme = createTheme({
  palette: {
    primary: {
      main: '#3a86ff',
      light: '#5f9dff',
      dark: '#1e5fcf',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#495057',
      light: '#636b72',
      dark: '#343a40',
    },
    background: {
      default: '#f8f9fa',
      paper: '#ffffff',
    },
    text: {
      primary: '#212529',
      secondary: '#495057',
    },
    info: {
      main: '#0099cc',
    },
    success: {
      main: '#00b894',
    },
    error: {
      main: '#ff6b6b',
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h4: {
      fontWeight: 700,
      fontSize: '1.75rem',
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.4rem',
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.15rem',
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: '1.05rem',
    },
    subtitle2: {
      fontWeight: 500,
      color: '#495057',
    },
  },
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none',
          padding: '8px 16px',
          fontWeight: 500,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: '0 4px 12px rgba(58, 134, 255, 0.2)',
          },
        },
        containedPrimary: {
          backgroundColor: '#3a86ff',
          '&:hover': {
            backgroundColor: '#5f9dff',
          },
        },
        outlined: {
          borderWidth: '1.5px',
          '&:hover': {
            borderWidth: '1.5px',
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        rounded: {
          borderRadius: '12px',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          boxShadow: '0 2px 12px rgba(0, 0, 0, 0.08)',
          overflow: 'hidden',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '10px',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          margin: '16px 0',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: '#e9ecef',
          color: '#495057',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
    },
  },
});

// Create an isolated StreamDisplay component that doesn't rely on global window functions
const StreamDisplay = ({ text, containerRef }) => {
  const elementRef = useRef(null);
  
  // Update the text content when it changes
  useEffect(() => {
    if (elementRef.current && text) {
      // Replace newlines with <br> for proper formatting
      const formattedText = text.replace(/\n/g, '<br>');
      elementRef.current.innerHTML = formattedText;
      
      // Auto-scroll if needed
      if (containerRef?.current) {
        // Check if user is near the bottom
        const container = containerRef.current;
        const isNearBottom = container.scrollHeight - container.clientHeight - container.scrollTop < 150;
        
        // Only auto-scroll if user is already near the bottom
        if (isNearBottom) {
          setTimeout(() => {
            if (containerRef.current) {
              containerRef.current.scrollTop = containerRef.current.scrollHeight;
            }
          }, 10);
        }
      }
    }
  }, [text, containerRef]);
  
  return <div ref={elementRef} style={{ whiteSpace: 'pre-wrap', minHeight: '20px' }} />;
};

function InterviewPrep() {
  const [isRecording, setIsRecording] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [responses, setResponses] = useState([]);
  const [conversation, setConversation] = useState([]);
  const [specialInstructions, setSpecialInstructions] = useState('');
  const [selectedProfileType, setSelectedProfileType] = useState('dataEngineer');
  const [jobDescription, setJobDescription] = useState('');
  const [resumeContent, setResumeContent] = useState('');
  const [candidateName, setCandidateName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sessionStarted, setSessionStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  const mediaRecorder = useRef(null);
  const deepgramLive = useRef(null);
  const transcriptRef = useRef('');
  const [streamingResponse, setStreamingResponse] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const isMobile = useMediaQuery(interviewTheme.breakpoints.down('sm'));
  const chatContainerRef = useRef(null);
  const [infoAnchorEl, setInfoAnchorEl] = useState(null);

  // Create a state to track if StreamDisplay is mounted and ready
  const [isStreamDisplayReady, setIsStreamDisplayReady] = useState(false);

  // Modify the chat container to ensure proper scrolling
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [conversation]);

  // Add separate effect for initial streaming state change
  useEffect(() => {
    if (isStreaming && chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [isStreaming]);

  // Effect to show modal on first load
  useEffect(() => {
    if (!sessionStarted) {
      setIsModalOpen(true);
    }
  }, [sessionStarted]);

  // Get the appropriate prompt based on the selected profile type
  const getSelectedPrompt = () => {
    switch (selectedProfileType) {
      case 'sdetQA':
        return sdetQAPrompt;
      case 'dotNet':
        return dotNetPrompt;
      case 'dataEngineer':
      default:
        return dataEngineerPrompt;
    }
  };

  // Generate the customized prompt with job description and resume
  const getCustomizedPrompt = () => {
    const baseSelectedPrompt = getSelectedPrompt();
    
    let customPrompt = baseSelectedPrompt;
    
    if (candidateName) {
      // Replace "You are a" with "You are [name], a"
      customPrompt = customPrompt.replace(
        'You are a', 
        `You are ${candidateName}, a`
      );
    }
    
    if (jobDescription) {
      customPrompt = `Job Description:\n${jobDescription}\n\n${customPrompt}`;
    }
    
    if (resumeContent) {
      // Insert resume content right after the professional summary
      const summaryEndIndex = customPrompt.indexOf('• **Core Skills & Expertise**');
      if (summaryEndIndex !== -1) {
        customPrompt = 
          customPrompt.substring(0, summaryEndIndex) + 
          `\n• **Additional Background**\n${resumeContent}\n\n` + 
          customPrompt.substring(summaryEndIndex);
      } else {
        customPrompt = `${customPrompt}\n\n• **Additional Background**\n${resumeContent}`;
      }
    }
    
    return customPrompt;
  };

  // Function to save conversation data to backend
  const saveConversationToBackend = async (conversationData) => {
    try {
      const userid = localStorage.getItem('userid');
      if (!userid) {
        console.error('User ID not found in localStorage');
        return;
      }

      // Get the Auth0 token
      const token = await getAccessTokenSilently();
      
      // Prepare the data to send
      const payload = {
        userid,
        json: {
          ...conversationData,
          profileType: selectedProfileType,
          jobDescription,
          resumeContent,
          candidateName
        }
      };

      // Fire and forget API call - don't wait for response
      fetch(baseurl + save_interview_conversation, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload),
      }).catch(error => {
        console.error('Error sending conversation data:', error);
      });
    } catch (error) {
      console.error('Error in saveConversationToBackend:', error);
    }
  };

  useEffect(() => {
    console.log('Current transcript:', transcript);
    transcriptRef.current = transcript;
  }, [transcript]);

  useEffect(() => {
    console.log('Current responses:', responses);
    // Save conversation data whenever responses change and we have at least one response
    if (responses.length > 0) {
      // Using IIFE to handle the async function
      (async () => {
        try {
          await saveConversationToBackend({
            responses,
            conversation,
            specialInstructions: specialInstructions || null 
          });
        } catch (error) {
          console.error('Error calling saveConversationToBackend:', error);
        }
      })();
    }
  }, [responses, conversation, specialInstructions]);

  useEffect(() => {
    if (isRecording && deepgramLive.current) {
      const handleTranscript = (data) => {
        console.log('Received transcription result:', data);
        const receivedTranscript = data.channel.alternatives[0].transcript;
        if (receivedTranscript) {
          setTranscript(prev => {
            const newTranscript = prev + ' ' + receivedTranscript.trim();
            console.log('Updated transcript:', newTranscript);
            transcriptRef.current = newTranscript;
            return newTranscript;
          });
        }
      };

      deepgramLive.current.addListener(LiveTranscriptionEvents.Transcript, handleTranscript);

      deepgramLive.current.addListener(LiveTranscriptionEvents.Error, (error) => {
        console.error('Deepgram error:', error);
      });

      return () => {
        if (deepgramLive.current) {
          deepgramLive.current.removeListener(LiveTranscriptionEvents.Transcript, handleTranscript);
        }
      };
    }
  }, [isRecording]);

  const startRecording = async () => {
    console.log('Starting recording...');
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log('Got audio stream');
      mediaRecorder.current = new MediaRecorder(stream);

      const deepgram = createClient('ad0e8b7a2c31eae525a110501a9576180e6f4cec');
      console.log('Created Deepgram client');

      deepgramLive.current = deepgram.listen.live({
        model: "nova-2",
        language: "en-US",
        smart_format: true,
      });

      console.log('Created Deepgram live connection');

      deepgramLive.current.addListener(LiveTranscriptionEvents.Open, () => console.log('Deepgram connection opened'));
      deepgramLive.current.addListener(LiveTranscriptionEvents.Close, () => console.log('Deepgram connection closed'));

      mediaRecorder.current.addEventListener('dataavailable', async (event) => {
        if (event.data.size > 0 && deepgramLive.current.getReadyState() === 1) {
          const arrayBuffer = await event.data.arrayBuffer();
          deepgramLive.current.send(arrayBuffer);
          console.log('Sent data to Deepgram, size:', arrayBuffer.byteLength);
        }
      });

      mediaRecorder.current.start(1000);
      setIsRecording(true);
      console.log('Started recording');
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  const stopRecording = async () => {
    console.log('Stopping recording...');
    if (mediaRecorder.current) {
      const currentTranscript = transcriptRef.current;
      console.log('Current transcript from ref before stopping:', currentTranscript);

      mediaRecorder.current.stop();
      console.log('Stopped media recorder');
      
      if (deepgramLive.current) {
        deepgramLive.current.finish();
        console.log('Finished Deepgram connection');
      }

      setIsRecording(false);

      await new Promise(resolve => setTimeout(resolve, 300));

      const finalTranscript = transcriptRef.current;
      console.log('Final transcript from ref after stopping:', finalTranscript);

      if (finalTranscript.trim()) {
        console.log('Sending to AI with transcript from ref:', finalTranscript);
        await sendToAI(false, finalTranscript);
      } else {
        console.log('No transcript available to send to AI');
      }
    }
  };

  const handleMouseDown = () => {
    if (!isRecording) {
      startRecording();
    }
  };

  const handleMouseUp = async () => {
    if (isRecording) {
      console.log('Mouse released, current transcript from ref:', transcriptRef.current);
      await stopRecording();
    }
  };

  const handleKeyDown = (event) => {
    // Check if the active element is a text field or textarea
    const activeElement = document.activeElement;
    const isInputActive = activeElement.tagName === 'INPUT' || 
                          activeElement.tagName === 'TEXTAREA' || 
                          activeElement.isContentEditable;
    
    // Only proceed if not typing in a text field
    if (event.code === 'Space' && !isRecording && !isInputActive) {
      event.preventDefault();
      startRecording();
    }
  };

  const handleKeyUp = async (event) => {
    // Check if the active element is a text field or textarea
    const activeElement = document.activeElement;
    const isInputActive = activeElement.tagName === 'INPUT' || 
                          activeElement.tagName === 'TEXTAREA' || 
                          activeElement.isContentEditable;
    
    // Only proceed if not typing in a text field
    if (event.code === 'Space' && isRecording && !isInputActive) {
      event.preventDefault();
      console.log('Space released, current transcript:', transcript);
      await stopRecording();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [isRecording]);

  const handleInfoClick = (event) => {
    setInfoAnchorEl(event.currentTarget);
  };

  const handleInfoClose = () => {
    setInfoAnchorEl(null);
  };

  const infoOpen = Boolean(infoAnchorEl);
  const infoId = infoOpen ? 'interview-info-popover' : undefined;

  const sendToAI = async (regenerate = false, transcriptToSend = null) => {
    console.log('Sending to AI service...');
    setIsLoading(true);

    try {
      // Get the Auth0 token
      const token = await getAccessTokenSilently();
      const userid = localStorage.getItem('userid');
      
      if (!userid) {
        console.error('User ID not found in localStorage');
        setIsLoading(false);
        return;
      }
      
      let currentTranscript;
      
      if (regenerate && conversation.length >= 2) {
        currentTranscript = conversation[conversation.length - 2].content;
      } else if (transcriptToSend) {
        currentTranscript = transcriptToSend;
      } else {
        currentTranscript = transcriptRef.current;
      }

      console.log('Transcript being sent to AI service:', currentTranscript);
      if (!currentTranscript.trim()) {
        console.log('No transcript to send. Skipping AI request.');
        setIsLoading(false);
        return;
      }

      let updatedConversation;
      if (regenerate) {
        updatedConversation = conversation.slice(0, -1);
      } else {
        // Immediately add the user message to conversation for instant display
        const newUserMessage = { role: 'user', content: currentTranscript };
        setConversation(prev => [...prev, newUserMessage]);
        
        updatedConversation = conversation
          .filter(msg => msg.role === 'assistant')
          .concat([newUserMessage]);
      }

      // Use the customized prompt with job description and resume
      const customizedPrompt = getCustomizedPrompt();
      const fullPrompt = (specialInstructions ? `${specialInstructions}\n\n` : '') + customizedPrompt + updatedConversation.map(msg => 
        `${msg.role === 'user' ? 'Interviewer' : candidateName || 'Candidate'}: ${msg.content}`
      ).join('\n');

      // Reset streaming state and clear display
      setStreamingResponse('');
      
      // Show streaming container immediately before making the API call
      setIsStreaming(true);
      
      // Ensure scroll position is at the bottom before streaming starts
      if (chatContainerRef.current) {
        // Check if user is near the bottom before forcing scroll
        const container = chatContainerRef.current;
        const isNearBottom = container.scrollHeight - container.clientHeight - container.scrollTop < 150;
        
        // Only auto-scroll if user is already near the bottom
        if (isNearBottom) {
          chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
      }
      
      console.log('--- DEBUG: Sending request to API');
      
      // Setup a timeout to hide the loading indicator after a short delay
      // This ensures we don't show the loading spinner for too long
      const loadingTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      
      try {
        const response = await fetch(baseurl + stream_anthropic, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            prompt: currentTranscript,
            basePrompt: fullPrompt,
            userid: userid
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        console.log('--- DEBUG: Got response from API, starting to read stream');
        
        if (!response.body) {
          throw new Error('ReadableStream not supported in this browser.');
        }
        
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let buffer = '';
        let groqResponse = '';
        
        // Process stream chunks immediately as they arrive
        while (true) {
          const { done, value } = await reader.read();
          
          if (done) {
            console.log('--- DEBUG: Stream complete');
            break;
          }
          
          // Decode and process chunk immediately
          const chunk = decoder.decode(value, { stream: true });
          buffer += chunk;
          
          // Extract and process complete events
          let endIndex;
          while ((endIndex = buffer.indexOf('\n\n')) !== -1) {
            const eventText = buffer.substring(0, endIndex);
            buffer = buffer.substring(endIndex + 2);
            
            if (!eventText.trim()) continue;
            
            // Parse event type and data
            const eventLines = eventText.split('\n');
            let eventType = '';
            let eventData = '';
            
            for (const line of eventLines) {
              if (line.startsWith('event:')) {
                eventType = line.substring(6).trim();
              } else if (line.startsWith('data:')) {
                eventData = line.substring(5).trim();
              }
            }
            
            if (!eventType || !eventData) continue;
            
            try {
              const data = JSON.parse(eventData);
              
              // Handle different event types
              switch (eventType) {
                case 'content_block_delta':
                  if (data.delta?.type === 'text_delta' && data.delta?.text) {
                    // This is the text chunk we need to display
                    const textChunk = data.delta.text;
                    groqResponse += textChunk;
                    
                    // Update response state for immediate display
                    setStreamingResponse(groqResponse);
                  }
                  break;
                  
                case 'message_start':
                  // Message is starting, clear any previous content
                  console.log('--- DEBUG: Message stream started');
                  break;
                  
                case 'message_stop':
                  // Message is complete
                  console.log('--- DEBUG: Message stream completed');
                  break;
                  
                case 'content_block_start':
                  // Content block starting
                  console.log('--- DEBUG: Content block started');
                  break;
                  
                case 'content_block_stop':
                  // Content block finished
                  console.log('--- DEBUG: Content block stopped');
                  break;
                  
                case 'ping':
                  // Just a keepalive ping
                  break;
                  
                default:
                  console.log(`--- DEBUG: Unknown event type: ${eventType}`);
              }
            } catch (error) {
              console.error(`--- DEBUG: Error parsing event data:`, error);
            }
          }
        }

        // After all streaming is done, update the conversation
        console.log(`--- DEBUG: Stream complete, final response: "${groqResponse.slice(0, 50)}..."`);
        
        // Store the streaming response in state for later use
        setStreamingResponse(groqResponse);
        
        // Properly update the conversation with the AI response
        // We already added the user message earlier for immediate display
        setConversation(prevConversation => {
          return [...prevConversation, { role: 'assistant', content: groqResponse }];
        });
        
        setResponses(prevResponses => [...prevResponses, groqResponse]);
        
        // Clear input but keep the final response visible
        setTranscript('');
        setIsStreaming(false);
        
        // Clear the loading timeout if it's still active
        clearTimeout(loadingTimeout);
        setIsLoading(false);
        
        console.log('--- DEBUG: Conversation updated with new response');
      } catch (error) {
        console.error('--- DEBUG: Error in sendToAI:', error);
        setIsStreaming(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('--- DEBUG: Error in sendToAI:', error);
      setIsStreaming(false);
      setIsLoading(false);
    }
  };

  const clearConversation = () => {
    setConversation([]);
    setResponses([]);
    setTranscript('');
    setStreamingResponse('');
    setIsModalOpen(true); // Open the modal to start a new interview
  };

  const handleTranscriptChange = (e) => {
    setTranscript(e.target.value);
    transcriptRef.current = e.target.value;
  };

  const handleManualSend = async () => {
    const currentTranscript = transcriptRef.current;
    if (currentTranscript.trim()) {
      console.log('Manually sending transcript:', currentTranscript);
      // Clear input field immediately for better UX
      setTranscript('');
      transcriptRef.current = '';
      await sendToAI(false, currentTranscript);
    }
  };

  const handleRegenerateAnswer = async () => {
    console.log('Regenerating answer...');
    if (conversation.length > 0) {
      setStreamingResponse('');
      await sendToAI(true);
    }
  };

  const handleSaveModal = (data) => {
    setSelectedProfileType(data.profileType);
    setJobDescription(data.jobDescription);
    setResumeContent(data.resumeContent);
    setSpecialInstructions(data.specialInstructions);
    setCandidateName(data.candidateName);
    setSessionStarted(true);
  };

  // Get role title for display
  const getRoleTitle = () => {
    switch (selectedProfileType) {
      case 'sdetQA':
        return 'SDET/QA Engineer';
      case 'dotNet':
        return '.NET Developer';
      case 'dataEngineer':
      default:
        return 'Data Engineer';
    }
  };

  const handleTextFieldKeyDown = (event) => {
    // Send message on Enter without Shift
    if (event.key === 'Enter' && !event.shiftKey && !isStreaming && !isLoading) {
      event.preventDefault();
      handleManualSend();
    }
  };

  return (
    <ThemeProvider theme={interviewTheme}>
      <Container maxWidth="lg" sx={{ pb: 4 }}>
        <Box sx={{ pt: 4, pb: 2 }}>
          {/* Header section */}
          <Grid container spacing={2} alignItems="center" justifyContent="space-between">
            <Grid item xs={12} md={8}>
              <Typography variant="h4" gutterBottom>
                AI Interview Practice
              </Typography>
              
              {sessionStarted && (
                <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
                  <Chip 
                    label={getRoleTitle()} 
                    color="primary" 
                    size="medium"
                  />
                  {candidateName && (
                    <Chip 
                      label={`Candidate: ${candidateName}`}
                      variant="outlined" 
                      size="medium"
                    />
                  )}
                </Stack>
              )}
            </Grid>
            
            <Grid item xs={12} md={4} sx={{ textAlign: { xs: 'left', md: 'right' } }}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<AddIcon />}
                onClick={clearConversation}
                sx={{ mr: 1 }}
              >
                New Interview
              </Button>
              
              <Tooltip title="Interview Information">
                <IconButton 
                  color="primary" 
                  onClick={handleInfoClick} 
                  aria-describedby={infoId}
                >
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>

        {/* Main content - Now takes full width since info panel is in a popover */}
        <Grid container spacing={3}>
          {/* Conversation history */}
          <Grid item xs={12}>
            <Card 
              sx={{ 
                height: { xs: 'calc(100vh - 230px)', md: 'calc(100vh - 270px)' }, 
                display: 'flex', 
                flexDirection: 'column',
                minHeight: '500px',
                position: 'relative',
                overflow: 'hidden', // Prevent double scrollbars
                maxWidth: '100%' // Ensure no horizontal overflow
              }}
            >
              <CardContent 
                sx={{ 
                  p: 0,
                  display: 'flex', 
                  flexDirection: 'column',
                  height: '100%',
                  overflow: 'hidden', // Prevent double scrollbars
                  pb: '0 !important', // Override default padding bottom
                  position: 'relative' // For absolute positioning child elements
                }}
              >
                {/* Chat messages area with scrolling */}
                <Box 
                  ref={chatContainerRef} 
                  sx={{ 
                    flex: '1 1 auto',
                    overflowY: 'auto', 
                    overflowX: 'hidden',
                    p: 3, 
                    pb: '180px', // Increase padding to prevent bottom content from being cut off
                    display: 'flex', 
                    flexDirection: 'column', 
                    gap: 3,
                    height: '100%', // Take full height
                    maxHeight: '100%', // Ensure it doesn't exceed parent
                    '&::-webkit-scrollbar': {
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: 'rgba(0, 0, 0, 0.05)',
                      borderRadius: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: 'rgba(0, 0, 0, 0.15)',
                      borderRadius: '8px',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.25)',
                      },
                    },
                  }}
                >
                  {conversation.length === 0 && !isStreaming ? (
                    <Box 
                      sx={{ 
                        flex: 1, 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center', 
                        flexDirection: 'column', 
                        textAlign: 'center' 
                      }}
                    >
                      <Typography variant="h6" color="text.secondary" gutterBottom>
                        Start your interview practice session
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        Type your question below or click the microphone button to speak
                      </Typography>
                    </Box>
                  ) : (
                    <>
                      {conversation.map((message, index) => (
                        <Box 
                          key={index} 
                          sx={{
                            display: 'flex',
                            gap: 2,
                            width: '100%',
                            maxWidth: '100%',
                            overflowWrap: 'break-word',
                            wordWrap: 'break-word',
                          }}
                        >
                          <Avatar sx={{
                            bgcolor: message.role === 'user' ? '#e9ecef' : '#e1f5fe',
                            color: message.role === 'user' ? '#495057' : '#0288d1',
                            flexShrink: 0,
                          }}>
                            {message.role === 'user' ? <PersonIcon /> : <SmartToyIcon />}
                          </Avatar>
                          
                          <Box sx={{ flex: 1, maxWidth: 'calc(100% - 48px)' }}>
                            <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
                              {message.role === 'user' ? 'Interviewer' : (candidateName || 'AI Candidate')}
                            </Typography>
                            <Typography variant="body1" sx={{ 
                              whiteSpace: 'pre-wrap',
                              overflowWrap: 'break-word',
                              wordWrap: 'break-word',
                            }}>
                              {message.content}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                    
                      {isStreaming && (
                        <Box 
                          sx={{
                            display: 'flex',
                            gap: 2,
                            width: '100%',
                            maxWidth: '100%',
                            overflowWrap: 'break-word',
                            wordWrap: 'break-word',
                          }}
                        >
                          <Avatar sx={{
                            bgcolor: '#e1f5fe',
                            color: '#0288d1',
                            flexShrink: 0,
                          }}>
                            <SmartToyIcon />
                          </Avatar>
                          
                          <Box sx={{ flex: 1, maxWidth: 'calc(100% - 48px)' }}>
                            <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
                              {candidateName || 'AI Candidate'} {isLoading && '(thinking...)'}
                            </Typography>
                            {isLoading ? (
                              <CircularProgress size={20} sx={{ ml: 1 }} />
                            ) : (
                              <Box sx={{ 
                                minHeight: '1.5em',
                                overflowWrap: 'break-word',
                                wordWrap: 'break-word', 
                              }}>
                                {/* Use the new StreamDisplay component with direct text passing */}
                                <StreamDisplay text={streamingResponse} containerRef={chatContainerRef} />
                              </Box>
                            )}
                          </Box>
                        </Box>
                      )}
                    </>
                  )}
                </Box>
                
                {/* Input area - fixed at bottom */}
                <Box 
                  sx={{ 
                    py: { xs: 1.5, md: 2.5 },
                    px: { xs: 1.5, md: 2 },
                    borderTop: '1px solid',
                    borderColor: 'divider',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    bgcolor: 'background.paper',
                    zIndex: 10,
                    width: '100%',
                    boxShadow: '0px -2px 8px rgba(0,0,0,0.05)',
                  }}
                >
                  <Grid container spacing={{ xs: 1, md: 2 }} alignItems="flex-end">
                    <Grid item xs>
                      <TextField
                        fullWidth
                        placeholder="Type your interview question..."
                        value={transcript}
                        onChange={handleTranscriptChange}
                        onKeyDown={handleTextFieldKeyDown}
                        variant="outlined"
                        multiline
                        rows={3}
                        disabled={isStreaming || isLoading}
                        InputProps={{
                          sx: {
                            borderRadius: '24px',
                            pr: '12px',
                          }
                        }}
                      />
                    </Grid>
                    <Grid item sx={{ display: 'flex', alignItems: 'center', alignSelf: 'flex-end' }}>
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <IconButton
                          color={isRecording ? 'error' : 'primary'}
                          onMouseDown={handleMouseDown}
                          onMouseUp={handleMouseUp}
                          onTouchStart={handleMouseDown}
                          onTouchEnd={handleMouseUp}
                          disabled={isStreaming || isLoading}
                          sx={{ 
                            boxShadow: isRecording ? '0 0 0 2px rgba(244, 67, 54, 0.2)' : 'none',
                            transition: 'all 0.2s'
                          }}
                        >
                          {isRecording ? <MicIcon /> : <MicOffIcon />}
                        </IconButton>
                        
                        {conversation.length > 0 && (
                          <IconButton 
                            color="primary" 
                            onClick={handleRegenerateAnswer}
                            disabled={isStreaming || isLoading || conversation.length === 0}
                          >
                            <RefreshIcon />
                          </IconButton>
                        )}
                        
                        <IconButton 
                          color="primary" 
                          onClick={handleManualSend}
                          disabled={isStreaming || isLoading || !transcript.trim()}
                        >
                          <SendIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      
      {/* Info Popover that replaces the interview information panel */}
      <Popover
        id={infoId}
        open={infoOpen}
        anchorEl={infoAnchorEl}
        onClose={handleInfoClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: { 
            width: { xs: '90%', sm: '400px' },
            maxWidth: '400px',
            mt: 1,
            boxShadow: '0 8px 24px rgba(0, 0, 0, 0.12)'
          }
        }}
      >
        <Card elevation={0}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Interview Information
            </Typography>
            
            <Divider />
            
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2" gutterBottom>
                Role
              </Typography>
              <Typography variant="body1" gutterBottom>
                {getRoleTitle()}
              </Typography>
              
              {jobDescription && (
                <>
                  <Typography variant="subtitle2" sx={{ mt: 2 }} gutterBottom>
                    Job Description
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ 
                    maxHeight: '150px', 
                    overflowY: 'auto', 
                    pr: 1, 
                    fontSize: '0.9rem',
                    whiteSpace: 'pre-wrap'
                  }}>
                    {jobDescription}
                  </Typography>
                </>
              )}
              
              {specialInstructions && (
                <>
                  <Typography variant="subtitle2" sx={{ mt: 2 }} gutterBottom>
                    Special Instructions
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ 
                    maxHeight: '100px', 
                    overflowY: 'auto', 
                    pr: 1, 
                    fontSize: '0.9rem',
                    whiteSpace: 'pre-wrap'
                  }}>
                    {specialInstructions}
                  </Typography>
                </>
              )}
            </Box>
            
            <Box sx={{ mt: 3 }}>
              <Typography variant="subtitle2" gutterBottom>
                Help & Tips
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                • Press and hold <b>Space</b> to record your question
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                • Click the <b>microphone</b> button to start/stop recording
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                • Use <b>regenerate</b> to get a different response
              </Typography>
              <Typography variant="body2" color="text.secondary">
                • Click <b>New Interview</b> to start over
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Popover>
      
      {/* Interview setup modal */}
      <InterviewModal 
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSessionStarted(true);
        }}
        onSave={handleSaveModal}
      />
    </ThemeProvider>
  );
}

export default InterviewPrep; 