import React from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import { keyframes } from '@mui/system';
import googleLogo from '../../images/logos/google.png';
import amazonLogo from '../../images/logos/aws.png';
import appleLogo from '../../images/logos/apple.png';
import azureLogo from '../../images/logos/azure.png';
import intelLogo from '../../images/logos/intel.png';
import oracleLogo from '../../images/logos/oracle.png';
import salesforceLogo from '../../images/logos/salesforce.png';
import adobeLogo from '../../images/logos/adobe.png';
import uberLogo from '../../images/logos/uber.png';
import airbnbLogo from '../../images/logos/airbnb.png';
import slackLogo from '../../images/logos/slack.png';
import twilioLogo from '../../images/logos/twilio.png';
import shopifyLogo from '../../images/logos/shopify.png';
import squareLogo from '../../images/logos/square.png';
import metaLogo from '../../images/logos/meta.png';
import netflixLogo from '../../images/logos/netflix.png';
import teslaLogo from '../../images/logos/tesla.png';

// Sample company logos
const companies = [
    { name: 'Google', logo: googleLogo },
    { name: 'AWS', logo: amazonLogo },
    { name: 'Apple', logo: appleLogo },
    { name: 'Azure', logo: azureLogo },
    { name: 'Meta', logo: metaLogo },
    { name: 'Netflix', logo: netflixLogo },
    { name: 'Tesla', logo: teslaLogo },
    { name: 'Intel', logo: intelLogo },
    { name: 'Oracle', logo: oracleLogo },
    { name: 'Salesforce', logo: salesforceLogo },
    { name: 'Adobe', logo: adobeLogo },
    { name: 'Uber', logo: uberLogo },
    { name: 'Airbnb', logo: airbnbLogo },
    { name: 'Slack', logo: slackLogo },
    { name: 'Twilio', logo: twilioLogo },
    { name: 'Shopify', logo: shopifyLogo },
];

const scroll = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
`;

const CompanyLogos = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box sx={{ overflow: 'hidden', padding: 2, marginTop: { xs: 4, sm: 6, md: 8 }, marginBottom: { xs: 4, sm: 6, md: 8 } }}>

            <Box sx={{ height: { xs: 10, sm: 15, md: 20 } }} />
            <Box sx={{ 
                display: 'flex', 
                width: '200%',
                animation: `${scroll} ${isMobile ? '60s' : '120s'} linear infinite`,
                '&:hover': {
                    animationPlayState: 'paused'
                },
                marginTop: { xs: 2, sm: 3, md: 4 },
                marginBottom: { xs: 2, sm: 3, md: 4 }
            }}>
                {[...companies, ...companies].map((company, index) => (
                    <Box
                        key={index}
                        sx={{
                            flex: '0 0 auto',
                            margin: { xs: '0 15px', sm: '0 15px', md: '0 20px' },
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: { xs: '60px', sm: '50px', md: '60px', lg: '80px' },
                            height: { xs: '60px', sm: '50px', md: '60px', lg: '80px' },
                        }}
                    >
                        <img 
                            src={company.logo} 
                            alt={company.name} 
                            style={{ 
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain' 
                            }} 
                        />
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default CompanyLogos;
